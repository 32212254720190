import api from '../constants/api'

export const fetchGet = async (url) => (
    await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Pragma": "no-cache"
        },
        credentials: 'include'
    }).then(checkStatus)
)

export const fetchPost = async (url, bodyJson) => (
    await fetch(url, {
        method: "POST",
        body: bodyJson,
        headers: {
            "Content-Type": "application/json",
            "Pragma": "no-cache"
        },
        credentials: 'include'
    }).then(checkStatus)
)

const checkStatus = async (response) => {
    switch (response.status) {
        case api.responseStatuses.OK:
            return response;
        case api.responseStatuses.Unauthorized:
            if (response.url.endsWith("checkAutorization")) {
                return response;
            }
            document.location.href = '/Login';
            throw api.messages.unauthorized;
        case api.responseStatuses.NotFound:
            throw api.messages.badRequest;
        case api.responseStatuses.BadRequest:
            const responseValue = await response.json();
            let error = api.messages.somethingWrong;

            if (responseValue && responseValue.message) {
                error = responseValue.message;
            }

            throw error;
        case api.responseStatuses.ServerError:
            throw api.messages.requestError
        default: throw api.messages.requestError
    }
}