import { GET_INITIAL_STATE } from '../constants/Index'
import {
    CHANGE_USER_NAME,
    CHANGE_PASSWORD,
    ON_LOGIN_REQUEST,
    ON_LOGIN_SUCCESS,
    ON_LOGIN_FAIL,
    CHECK_AUTORIZATION_REQUEST,
    CHECK_AUTORIZATION_SUCCESS,
    CHECK_AUTORIZATION_FAIL,
    CLEAR_LOCAL_STORAGE
} from '../constants/Login'
import { Clear as clearLocalStorage } from '../helpers/localStorageHelper'

const initialState = {
    login: '',
    password: '',
    errorMessage: '',
    isLoading: false,
    isGlobalLoading: false,
    errorMessageGlobal: ''
}

const handler = {
    [GET_INITIAL_STATE]: (state) => ({ ...state }),

    [CHANGE_USER_NAME]: (state, action) => ({
        ...state,
        login: action.value
    }),
    [CHANGE_PASSWORD]: (state, action) => ({
        ...state,
        password: action.value
    }),
    [ON_LOGIN_REQUEST]: (state) => ({
        ...state,
        isLoading: true
    }),
    [ON_LOGIN_SUCCESS]: (state) => ({
        ...state,
        isLoading: false,
        login: '',
        password: ''
    }),
    [ON_LOGIN_FAIL]: (state, action) => ({
        ...state,
        isLoading: false,
        errorMessage: action.error,
        password: ''
    }),
    [CHECK_AUTORIZATION_REQUEST]: (state) => ({
        ...state,
        isGlobalLoading: true
    }),
    [CHECK_AUTORIZATION_SUCCESS]: (state) => ({
        ...state,
        isGlobalLoading: false,
    }),
    [CHECK_AUTORIZATION_FAIL]: (state, action) => ({
        ...state,
        isGlobalLoading: false,
        errorMessageGlobal: action.error,
    }),
    [CLEAR_LOCAL_STORAGE]: (state) => {
        clearLocalStorage();
        return state;
    }
}

export default function login(state = initialState, action) {
    if (handler[action.type]) {
        return handler[action.type](state, action);
    }
    return state;
}