import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import "./content/styles/app.css";
import "bootstrap/dist/css/bootstrap.min.css";
import configureStore from './store/configureStore'
import Main from './components/Main'
import { Route, Router } from 'react-router'
import { GET_INITIAL_STATE } from './constants/Index'
import history from "./store/history";
import Layout from './components/Shared/Layout'
import Customer from './components/Customer'
import Measurements from './components/Measurements'
import Schedule from './components/Schedule'
import Trainer from './components/Trainer'
import Login from './components/Shared/Login'
import NewTicket from './components/Customer/NewTicket'
import NewGoal from './components/Customer/NewGoal'
import NewVisit from './components/Customer/NewVisit'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from "date-fns/locale/ru";
import format from "date-fns/format";

const store = configureStore()
const targetElement = document.querySelector('#root')
const getInitialState = () => ({ type: GET_INITIAL_STATE });

class RuLocalizedUtils extends DateFnsUtils {
    getCalendarHeaderText(date) {
        return format(date, "LLLL", { locale: this.locale });
    }

    getDatePickerHeaderText(date) {
        return format(date, "dd MMMM", { locale: this.locale });
    }
}

export const initialState = async () => {
    store.dispatch(getInitialState());
}

initialState().then(() => {
    render(
        <div className="App">
            <Provider store={store}>
                <MuiPickersUtilsProvider utils={RuLocalizedUtils} locale={ruLocale}>
                    <Router history={history}>
                        <Layout>
                            <Route exact path="/Login" component={Login} />
                            <Route exact path="/" component={Main} />
                            <Route exact path="/Customer" component={Customer} />
                            <Route exact path="/Measurements" component={Measurements} />
                            <Route exact path="/Schedule" component={Schedule} />
                            <Route exact path="/Trainer" component={Trainer} />
                            <Route exact path="/NewTicket" component={NewTicket} />
                            <Route exact path="/NewGoal" component={NewGoal} />
                            <Route exact path="/NewVisit" component={NewVisit} />
                        </Layout>
                    </Router>
                </MuiPickersUtilsProvider>
            </Provider>
        </div >,
        targetElement
    )
})
