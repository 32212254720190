import React, { Component } from 'react';
import { connect } from 'react-redux';
import TreatmentInfo from './TreatmentInfo';
import { getTreatments } from '../../actions/MainActions';
import { bindActionCreators } from 'redux'
import { Grid } from '@material-ui/core'
import Loading from '../Shared/Loading'
class TreatmentsContainer extends Component {
    UNSAFE_componentWillMount() {
        this.props.getTreatments();
    }

    render() {
        const { treatmentsLoading, treatmentsRequestErrorMessage } = this.props;

        if (treatmentsLoading) {
            return <Loading />
        }

        if (treatmentsRequestErrorMessage) {
            return <Grid item xs={12} style={{ textAlign: "center" }}>
                {treatmentsRequestErrorMessage}
            </Grid>
        }

        return <TreatmentInfo />
    }
}

const mapStateToProps = state => ({
    treatmentsLoading: state.main.treatmentsLoading,
    treatmentsRequestErrorMessage: state.main.treatmentsRequestErrorMessage,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    getTreatments,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentsContainer);