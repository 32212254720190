import React from 'react'
import { Grid } from '@material-ui/core'

const Goals = ({ goals, onClick }) => (
    <Grid item xs={12}>
        <Grid item xs={12}>
            <strong>Цели:</strong>&nbsp;
            (<i style={{ cursor: "pointer" }} onClick={onClick}>
                добавить
            </i>)
        </Grid>
        <Grid item xs={12}>
            <span style={{ backgroundColor: "yellow" }}>{goals.join(', ')}</span>
        </Grid>
    </Grid>
)

export default Goals;
