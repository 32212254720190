export const convertToMMDD = (date) => {
    const convertedStartDate = new Date(date);
    const month = convertedStartDate.getMonth() + 1
    const day = convertedStartDate.getDate();
    const shortStartDate = day + "." + month;

    return shortStartDate;
}

export const convertToHHMM = (timeString) => {
    if (timeString.length !== 4)
        throw new Error("Incorrect a parameter");

    return timeString[0] + timeString[1] + ":" + timeString[2] + timeString[3];
}

export const convertDateToHHMM = (value) => {
    const date = new Date(value);
    date.setMinutes(date.getUTCMinutes() + date.getTimezoneOffset());
    return date.getHours() + ":" + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes());
}