import {
    GET_TREATMENTS_SUCCESS,
    GET_TREATMENTS_REQUEST,
    GET_TREATMENTS_FAIL,

    GET_CUSTOMERS_INSIDE_CLUB_SUCCESS,
    GET_CUSTOMERS_INSIDE_CLUB_REQUEST,
    GET_CUSTOMERS_INSIDE_CLUB_FAIL,

    TOGGLE_CUSTOMERS,
    TOGGLE_TREATMENTS
} from '../constants/Main'
import { GET_INITIAL_STATE } from '../constants/Index'

const initialState = {
    
}

const handler = {
    [GET_INITIAL_STATE]: (state) => ({
        ...state,
        treatmentsList: [],
        customersList: [],
        treatmentsLoading: false,
        customersLoading: false,
        showAllCustomersIfMany: false,
        showAllTreatmentsIfMany: false,
    }),

    [GET_TREATMENTS_REQUEST]: (state) => ({
        ...state,
        treatmentsLoading: true
    }),
    [GET_TREATMENTS_SUCCESS]: (state, action) => ({
        ...state,
        treatmentsList: action.data,
        showAllTreatmentsIfMany: state.showAllTreatmentsIfMany
            ? true
            : action.data && action.data.length <= 5 && action.data.length > 0,
        treatmentsLoading: false,
    }),
    [GET_TREATMENTS_FAIL]: (state, action) => ({
        ...state,
        treatmentsLoading: false,
        treatmentsRequestErrorMessage: action.error
    }),

    [GET_CUSTOMERS_INSIDE_CLUB_REQUEST]: (state) => ({
        ...state,
        customersLoading: true
    }),
    [GET_CUSTOMERS_INSIDE_CLUB_SUCCESS]: (state, action) => ({
        ...state,
        customersList: action.data,
        showAllCustomersIfMany: state.showAllCustomersIfMany
            ? true
            : action.data && action.data.length <= 5 && action.data.length > 0,
        customersLoading: false,
    }),
    [GET_CUSTOMERS_INSIDE_CLUB_FAIL]: (state, action) => ({
        ...state,
        customersLoading: false,
        customersRequestErrorMessage: action.error
    }),
    [TOGGLE_CUSTOMERS]: (state) => ({
        ...state,
        showAllCustomersIfMany: !state.showAllCustomersIfMany
    }),
    [TOGGLE_TREATMENTS]: (state) => ({
        ...state,
        showAllTreatmentsIfMany: !state.showAllTreatmentsIfMany
    }),
}

export default function main(state = initialState, action) {
    if (handler[action.type]) {
        return handler[action.type](state, action);
    }
    return state;
}