const baseUrl = '/api/v1/';
// const baseUrl = 'http://localhost:5001/api/v1/';

const api = {
    links: {
        getCustomersInsideClub: () => `${baseUrl}customers/GetInsideClub`,
        getAvailableTreatments: () => `${baseUrl}treatments`,
        getCustomerById: (id) => `${baseUrl}customers/${id}`,
        addMeasurement: () => `${baseUrl}customers/AddMeasurement`,
        downloadImage: () => `${baseUrl}images/DownloadImage`,
        getTrainerImage: () => `${baseUrl}trainers/GetImage`,
        getTrainerData: () => `${baseUrl}trainers/GetData`,
        getCurrentRevenue: () => `${baseUrl}trainers/GetCurrentRevenue`,
        login: () => `${baseUrl}account/Login`,
        logout: () => `${baseUrl}account/Logout`,
        checkAutorization: () => `${baseUrl}account/checkAutorization`,
        getTicketTypes: () => `${baseUrl}tickets/GetTicketTypes`,
        onClickNewTicket: () => `${baseUrl}tickets/NewTicket`,
        getAllGoals: (customerId) => `${baseUrl}customers/GetAllGoals/${customerId}`,
        onClickNewGoal: () => `${baseUrl}customers/NewGoal`,
        getTreatmentConfigs: () => `${baseUrl}visits/GetTreatmentConfigs`,
        checkVisit: () => `${baseUrl}visits/checkVisit`,
        getCustomerTickets: (customerId) => `${baseUrl}tickets/GetCustomerTickets/${customerId}`,
        createVisit: () => `${baseUrl}visits/createVisit`,
    },
    messages: {
        requestError: 'Нет ответа от сервера',
        emptySearchResult: 'Empty search result',
        dontKnowError: 'Something went wrong',
        badRequest: 'Bad request',
        notFound: 'Объект не найден',
        unauthorized: 'Не авторизован',
        somethingWrong: "Что-то пошло не так. Обратитесь в тех.поддержку!"
    },
    responseStatuses: {
        OK: 200,
        BadRequest: 400,
        Unauthorized: 401,
        NotFound: 404,
        ServerError: 500
    }
}

export default api;
