import { GET_INITIAL_STATE } from '../constants/Index'
import { CHANGE_SELECTED_TICKET_ID } from '../constants/Customer'
import {
    GET_TREATMENT_CONFIGS_REQUEST,
    GET_TREATMENT_CONFIGS_SUCCESS,
    GET_TREATMENT_CONFIGS_FAIL,

    CHECK_VISIT_REQUEST,
    CHECK_VISIT_SUCCESS,
    CHECK_VISIT_FAIL,

    CREATE_VISIT_REQUEST,
    CREATE_VISIT_SUCCESS,
    CREATE_VISIT_FAIL,

    CHECK_VISIT_TIMEOUT,
    SELECT_DATE,
    ADD_NEW_ITEM,
    CHANGE_TREATMENT_CONFIG_ID,
    REMOVE_ITEM
} from '../constants/Visit'

const getRoundDateTimeNow = () => {
    let now = new Date();
    while (now.getMinutes() % 5 !== 0) {
        now.setMinutes(now.getMinutes() + 1);
    }

    now.setSeconds(0);
    now.setMilliseconds(0);
    return now;
}

const initialState = {
    treatmentsConfigRequestLoading: false,
    treatmentsConfigRequestErrorMessage: '',
    allTreatmentsConfigs: [],
    selectedDate: getRoundDateTimeNow(),
    selectedItems: [{
        id: '',
        name: '',
        price: 0
    }],
    checkVisitRequestLoading: false,
    checkVistitRequestErrorMessage: '',
    checkedVisit: false,
    checkedDateTime: '',

    createVisitRequestLoading: false,
    createVistitRequestErrorMessage: '',
}

const handler = {
    [GET_INITIAL_STATE]: (state) => ({ ...state }),

    [GET_TREATMENT_CONFIGS_REQUEST]: (state) => ({
        ...state,
        treatmentsConfigRequestLoading: true
    }),
    [GET_TREATMENT_CONFIGS_SUCCESS]: (state, action) => ({
        ...state,
        allTreatmentsConfigs: action.data,
        treatmentsConfigRequestLoading: false,
        treatmentsConfigRequestErrorMessage: '',
    }),
    [GET_TREATMENT_CONFIGS_FAIL]: (state, action) => ({
        ...state,
        treatmentsConfigRequestLoading: false,
        treatmentsConfigRequestErrorMessage: action.error,
    }),

    [SELECT_DATE]: (state, action) => ({
        ...state,
        selectedDate: action.date,
        checkedVisit: false,
    }),

    [ADD_NEW_ITEM]: (state) => ({
        ...state,
        selectedItems: [
            ...state.selectedItems, { id: '', name: '', price: 0 }
        ],
        checkedVisit: false,
    }),

    [CHANGE_TREATMENT_CONFIG_ID]: (state, action) => ({
        ...state,
        checkedVisit: false,
        selectedItems: state.selectedItems.map((item, index) => (
            index !== action.index
                ? item
                : {
                    ...item,
                    id: action.id,
                    name: state.allTreatmentsConfigs.filter(i => i.id === action.id)[0].name,
                    price: state.allTreatmentsConfigs.filter(i => i.id === action.id)[0].price
                }
        )),
    }),

    [REMOVE_ITEM]: (state, action) => ({
        ...state,
        checkedVisit: false,
        selectedItems: [...state.selectedItems.filter((item, index) => index !== action.index)],
    }),

    [CHECK_VISIT_REQUEST]: (state) => ({
        ...state,
        checkVistitRequestErrorMessage: '',
        checkVisitRequestLoading: true,
    }),
    [CHECK_VISIT_SUCCESS]: (state, action) => ({
        ...state,
        selectedItems: action.data,
        checkVisitRequestLoading: false,
        checkedVisit: true,
        checkedDateTime: new Date()
    }),
    [CHECK_VISIT_FAIL]: (state, action) => ({
        ...state,
        checkVisitRequestLoading: false,
        checkVistitRequestErrorMessage: action.error
    }),
    [CHANGE_SELECTED_TICKET_ID]: (state) => ({
        ...state,
        checkedVisit: false,
    }),
    [CHECK_VISIT_TIMEOUT]: (state) => ({
        ...state,
        checkedVisit: false,
        checkVistitRequestErrorMessage: 'Таймаут. Проверьте запись еще раз!'
    }),

    [CREATE_VISIT_REQUEST]: (state) => ({
        ...state,
        createVistitRequestErrorMessage: '',
        createVisitRequestLoading: true,
    }),
    [CREATE_VISIT_SUCCESS]: (state) => ({
        ...state,
        createVisitRequestLoading: false,
    }),
    [CREATE_VISIT_FAIL]: (state, action) => ({
        ...state,
        createVisitRequestLoading: false,
        createVistitRequestErrorMessage: action.error
    }),
}

export default function main(state = initialState, action) {
    if (handler[action.type]) {
        return handler[action.type](state, action);
    }
    return state;
}