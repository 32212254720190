import React from 'react'
import { Grid } from '@material-ui/core'

const ToggleElement = ({ entityName, showAllIfMany, toggleAction, amount }) => (
    <Grid item xs={12}>
        <strong>{entityName} ({amount})&nbsp;</strong>
            <span style={{ cursor: "pointer" }} onClick={() => toggleAction()}>
            {showAllIfMany ? "v" : ">"}
        </span>
    </Grid>
)

export default ToggleElement;