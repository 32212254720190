import api from '../constants/api'
import {
    GET_TREATMENTS_SUCCESS,
    GET_TREATMENTS_REQUEST,
    GET_TREATMENTS_FAIL,

    GET_CUSTOMERS_INSIDE_CLUB_SUCCESS,
    GET_CUSTOMERS_INSIDE_CLUB_REQUEST,
    GET_CUSTOMERS_INSIDE_CLUB_FAIL,

    TOGGLE_CUSTOMERS,
    TOGGLE_TREATMENTS,
} from '../constants/Main'
import { SET_CUSTOMER_ID } from '../constants/Customer'
import { fetchGet } from '../helpers/fetchHelper'
import { goToPage } from './Route'

const getTreatmentsRequest = () => ({ type: GET_TREATMENTS_REQUEST });
const getTreatmentsSuccess = (data) => ({ type: GET_TREATMENTS_SUCCESS, data });
const getTreatmentsFail = (error) => ({ type: GET_TREATMENTS_FAIL, error });

const getCustomersInsideClubRequest = () => ({ type: GET_CUSTOMERS_INSIDE_CLUB_REQUEST });
const getCustomersInsideClubSuccess = (data) => ({ type: GET_CUSTOMERS_INSIDE_CLUB_SUCCESS, data });
const getCustomersInsideClubFail = (error) => ({ type: GET_CUSTOMERS_INSIDE_CLUB_FAIL, error });

export const toggleCustomers = () => ({ type: TOGGLE_CUSTOMERS });
export const toggleTreatments = () => ({ type: TOGGLE_TREATMENTS });

export const setCustomerId = (customerId) => {
    return (dispatch) => {
        dispatch({type: SET_CUSTOMER_ID, customerId});
        dispatch(goToPage('/Customer'));
    }
}

export const getTreatments = () => {
    return async (dispatch) => {
        dispatch(getTreatmentsRequest());

        await fetchGet(api.links.getAvailableTreatments())
            .then(result => result.json())
            .then(result => dispatch(getTreatmentsSuccess(result.data)))
            .catch((error) => {
                dispatch(getTreatmentsFail(error))
            })
    }
}

export const getCustomersInsideClub = () => {
    return async (dispatch) => {
        dispatch(getCustomersInsideClubRequest());

        await fetchGet(api.links.getCustomersInsideClub())
            .then(result => result.json())
            .then(result => dispatch(getCustomersInsideClubSuccess(result.data)))
            .catch((error) => {
                dispatch(getCustomersInsideClubFail(error))
            })
    }
}
