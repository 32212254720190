import React, { Component } from 'react'
import CustomerDetails from './CustomerDetails'
import Loading from '../Shared/Loading'
import { connect } from 'react-redux';
import { onClickCustomer } from '../../actions/CustomerActions'
import { bindActionCreators } from 'redux'
import { goToPage } from '../../actions/Route'

class Customer extends Component {
    UNSAFE_componentWillMount() {
        if (this.props.customerId) {
            this.props.onClickCustomer(this.props.customerId);
        } else {
            this.props.goToPage('/');
        }
    }

    render() {
        const { customerLoading } = this.props;
        if (customerLoading) {
            return <Loading />
        }
        return <CustomerDetails />
    }
}

const mapStateToProps = state => ({
    customerLoading: state.customer.customerLoading,
    customerId: state.customer.customer.id
})

const mapDispatchToProps = dispatch => bindActionCreators({
    onClickCustomer,
    goToPage
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
