import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Loading from '../Shared/Loading'
import '../../content/styles/newImage.css'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class NewImageModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showModalNewImage: props.showModalNewImage,
            toggleModalNewImage: props.toggleModalNewImage,
            imagePreviewUrl: '',
            inputText: "Выберите файл...",
            entityId: props.entityId,
            newImageErrorMessage: '',
            newImageLoading: false,
            onSave: props.onSave,
        };
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        nextState.showModalNewImage = nextProps.showModalNewImage;
    }

    newImageChange = (e) => {
        e.preventDefault();

        const reader = new FileReader();
        const file = e.target.files[0];

        reader.onloadend = () => {
            const result = reader.result;
            const fileName = file.name;
            this.setState({ imagePreviewUrl: result, inputText: fileName })
        }

        reader.readAsDataURL(file)
    }

    render() {
        const { showModalNewImage, toggleModalNewImage, imagePreviewUrl, inputText, entityId, newImageErrorMessage, newImageLoading, onSave } = this.state;

        let $imagePreview = null;

        if (imagePreviewUrl) {
            $imagePreview = (<img src={imagePreviewUrl} alt="Аватарка" />);
        } else {
            $imagePreview = (<div className="previewText">Пожалуйста, выберите файл для отображения</div>);
        }

        return <div>
            <Dialog open={showModalNewImage} onClose={toggleModalNewImage} TransitionComponent={Transition} maxWidth="xs">
                <div className="previewComponent">
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={toggleModalNewImage} aria-label="close">
                            <CloseIcon /> Закрыть
                        </IconButton>
                    </Toolbar>
                    <label htmlFor="files" className="fileInput">{inputText}</label>
                    <input
                        style={{ display: "none" }}
                        id="files"
                        type="file"
                        accept="image/*"
                        onChange={(e) => this.newImageChange(e)} />
                    <button
                        className="submitButton"
                        type="submit"
                        disabled={inputText === 'Выберите файл...' || !entityId || newImageLoading}
                        onClick={() => { onSave(entityId, imagePreviewUrl); toggleModalNewImage(); }}>Сохранить </button>
                    {newImageLoading && <Loading />}
                    {newImageErrorMessage && <div>{newImageErrorMessage}</div>}
                    <div className="imgPreview">
                        {$imagePreview}
                    </div>
                </div>
            </Dialog>
        </div>
    }
}

export default NewImageModal;