import React, { Component } from 'react'
import { Grid, FormControlLabel, Switch } from '@material-ui/core'
import defaultImage from '../../content/images/default-no-image.png'
import { connect } from 'react-redux';
import TrainerImage from './TrainerImage'
import { toggleModalNewTrainerImage, downloadTrainerImage, getTrainerData, getCurrentRevenue, changeOnlyMyWorkingDays } from '../../actions/TrainerActions'
import NewImageModal from '../Shared/NewImageModal'
import { bindActionCreators } from 'redux'
import Loading from '../Shared/Loading';

class Trainer extends Component {
    UNSAFE_componentWillMount() {
        this.props.getTrainerData();
        this.props.getCurrentRevenue();
    }

    render() {
        const { trainer, newImageError, showModalNewTrainerImage, downloadTrainerImage, toggleModalNewTrainerImage,
            amountWorkingDays, avgCustomersInDayInMyWorkingDays, planMonthCurrent, isDataLoading, dataErrorMessage,
            isCurrentRevenueLoading, currentRevenueErrorMessage, currentRevenue, onlyMyWorkingDays, changeOnlyMyWorkingDays,
            amountAnthropometricsMonth, amountMyAnthropometricsMonth, amountConsultationsMonth, amountMyConsultationsMonth,
            avgCustomersInDayMonth, currentMyRevenue } = this.props;

        if (dataErrorMessage) {
            return <Grid item xs={12} style={{ textAlign: "center" }}>
                {dataErrorMessage}
            </Grid>
        }

        return <Grid container alignItems="center">
            <Grid item xs={6} style={{ textAlign: "center" }}>
                <TrainerImage size="96px" />
            </Grid>

            {isDataLoading && <Grid item xs={6}><Loading /></Grid>}

            {!isDataLoading && <Grid item xs={6}>
                <Grid container>
                    <strong>
                        <Grid item xs={12} style={{ fontSize: "24px" }}>
                            {trainer.name}
                        </Grid>
                        <Grid item xs={12}>
                            Тренер
                        </Grid>
                    </strong>
                </Grid>
            </Grid>}
            {!isDataLoading && <Grid container>
                <Grid item xs={6} style={{ textAlign: "center" }}>
                    {trainer.id &&
                        <span>
                            <i style={{ cursor: "pointer" }} onClick={() => toggleModalNewTrainerImage()}>Изменить</i>
                            {newImageError && <span><br />{newImageError}</span>}
                        </span>}
                </Grid>
                <Grid item xs={6}></Grid>

                <Grid item xs={12}>
                    <img src={defaultImage} alt="default" style={{ width: "32px", marginRight: "12px" }} />
                    Смен в этом месяце:&nbsp;
                    <span style={{ fontSize: "24px" }}>
                        {amountWorkingDays}
                    </span>
                </Grid>
                
                <Grid item xs={12}>
                    <FormControlLabel control={
                        <Switch
                            checked={onlyMyWorkingDays}
                            onChange={changeOnlyMyWorkingDays}
                            name="checkedB"
                            color="primary"
                        />}
                        label="Только мои смены"
                    />
                </Grid>

                <Grid item xs={12}>
                    <img src={defaultImage} alt="default" style={{ width: "32px", marginRight: "12px" }} />
                    Посещаемость в день:&nbsp;
                    <span style={{ fontSize: "24px" }}>
                        {onlyMyWorkingDays ? avgCustomersInDayInMyWorkingDays : avgCustomersInDayMonth}
                    </span>
                </Grid>

                <Grid item xs={12}>
                    <img src={defaultImage} alt="default" style={{ width: "32px", marginRight: "12px" }} />
                    Замеров за месяц:&nbsp;
                    <span style={{ fontSize: "24px" }}>
                        {onlyMyWorkingDays ? amountMyAnthropometricsMonth : amountAnthropometricsMonth}
                    </span>
                </Grid>

                <Grid item xs={12}>
                    <img src={defaultImage} alt="default" style={{ width: "32px", marginRight: "12px" }} />
                    Консультаций за месяц:&nbsp;
                    <span style={{ fontSize: "24px" }}>
                        {onlyMyWorkingDays ? amountMyConsultationsMonth : amountConsultationsMonth}
                    </span>
                </Grid>

                {onlyMyWorkingDays && <Grid item xs={12}>
                    <img src={defaultImage} alt="default" style={{ width: "32px", marginRight: "12px" }} />
                    Выручка в мои месяцы:&nbsp;
                    <span style={{ fontSize: "24px", backgroundColor: "yellow" }}>
                            {currentRevenueErrorMessage
                                ? currentRevenueErrorMessage
                                : isCurrentRevenueLoading
                                    ? <Loading />
                                    : <span>{currentMyRevenue}р.</span>}
                    </span>
                </Grid>}

                <Grid item xs={12} style={{ textAlign: "center" }}>
                    <strong style={{ fontSize: "24px" }}>
                        Выручка клуба &nbsp;
                        <span style={{ backgroundColor: "yellow" }}>
                            {currentRevenueErrorMessage
                                ? currentRevenueErrorMessage
                                : isCurrentRevenueLoading
                                    ? <Loading />
                                    : <span>{currentRevenue}р.</span>}
                        </span>
                    </strong>
                </Grid>

                <Grid item xs={12} style={{ textAlign: "center", marginBottom: "18px" }}>
                    <strong style={{ fontSize: "24px" }}>
                        План &nbsp;
                    <span style={{ backgroundColor: "lightgray" }}>
                        {planMonthCurrent === 0
                            ? <span>План не установлен</span>
                            : <span>{planMonthCurrent}р.</span>}
                        </span>
                    </strong>
                </Grid>
                {planMonthCurrent > 0 && <Grid item xs={12} style={{ textAlign: "center", marginBottom: "18px" }}>
                    <strong style={{ fontSize: "24px" }}>
                        До плана не хватает &nbsp;
                        <span style={{ backgroundColor: "lightgray" }}>{planMonthCurrent - currentRevenue}р.</span>
                    </strong>
                </Grid>}

                <NewImageModal
                    showModalNewImage={showModalNewTrainerImage}
                    toggleModalNewImage={toggleModalNewTrainerImage}
                    entityId={trainer.id}
                    onSave={downloadTrainerImage} />
            </Grid>}
        </Grid>
    }
}

const mapStateToProps = state => ({
    trainer: state.trainer.trainer,
    newImageError: state.trainer.newImageError,
    showModalNewTrainerImage: state.trainer.showModalNewTrainerImage,
    amountWorkingDays: state.trainer.trainer.amountWorkingDays,
    avgCustomersInDayInMyWorkingDays: state.trainer.trainer.avgCustomersInDayInMyWorkingDays,
    planMonthCurrent: state.trainer.planMonthCurrent,
    isDataLoading: state.trainer.isDataLoading,
    dataErrorMessage: state.trainer.dataErrorMessage,
    isCurrentRevenueLoading: state.trainer.isCurrentRevenueLoading,
    currentRevenueErrorMessage: state.trainer.currentRevenueErrorMessage,
    currentRevenue: state.trainer.currentRevenue,
    currentMyRevenue: state.trainer.currentMyRevenue,
    amountAnthropometricsMonth: state.trainer.trainer.amountAnthropometricsMonth,
    amountMyAnthropometricsMonth: state.trainer.trainer.amountMyAnthropometricsMonth,
    amountConsultationsMonth: state.trainer.trainer.amountConsultationsMonth,
    amountMyConsultationsMonth: state.trainer.trainer.amountMyConsultationsMonth,
    onlyMyWorkingDays: state.trainer.onlyMyWorkingDays,
    avgCustomersInDayMonth: state.trainer.trainer.avgCustomersInDayMonth
})

const mapDispatchToProps = dispatch => bindActionCreators({
    toggleModalNewTrainerImage,
    downloadTrainerImage,
    getTrainerData,
    getCurrentRevenue,
    changeOnlyMyWorkingDays
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Trainer);