export const ON_CLICK_CUSTOMER_REQUEST = 'ON_CLICK_CUSTOMER_REQUEST'
export const ON_CLICK_CUSTOMER_SUCCESS = 'ON_CLICK_CUSTOMER_SUCCESS'
export const ON_CLICK_CUSTOMER_FAIL = 'ON_CLICK_CUSTOMER_FAIL'

export const CHANGE_MEASUREMENT_VALUE = 'CHANGE_MEASUREMENT_VALUE'

export const SAVE_NEW_MEASUREMENT_REQUEST = 'SAVE_NEW_MEASUREMENT_REQUEST'
export const SAVE_NEW_MEASUREMENT_SUCCESS = 'SAVE_NEW_MEASUREMENT_SUCCESS'
export const SAVE_NEW_MEASUREMENT_FAIL = 'SAVE_NEW_MEASUREMENT_FAIL'

export const DOWNLOAD_CUSTOMER_IMAGE_REQUEST = 'DOWNLOAD_CUSTOMER_IMAGE_REQUEST'
export const DOWNLOAD_CUSTOMER_IMAGE_SUCCESS = 'DOWNLOAD_CUSTOMER_IMAGE_SUCCESS'
export const DOWNLOAD_CUSTOMER_IMAGE_FAIL = 'DOWNLOAD_CUSTOMER_IMAGE_FAIL'

export const GET_TICKET_TYPES_REQUEST = 'GET_TICKET_TYPES_REQUEST'
export const GET_TICKET_TYPES_SUCCESS = 'GET_TICKET_TYPES_SUCCESS'
export const GET_TICKET_TYPES_FAIL = 'GET_TICKET_TYPES_FAIL'

export const TOGGLE_MODAL_NEW_IMAGE = 'TOGGLE_MODAL_NEW_IMAGE'
export const CHANGE_SELECTE_TtICKET_TYPE_ID = 'CHANGE_SELECTE_TtICKET_TYPE_ID'
export const CHANGE_SELECTED_DISCOUNT_NEW_TICKET = 'CHANGE_SELECTED_DISCOUNT_NEW_TICKET'
export const CHANGE_SELECTED_GOAL_ID = 'CHANGE_SELECTED_GOAL_ID'

export const ON_CLICK_NEW_TICKET_REQUEST = 'ON_CLICK_NEW_TICKET_REQUEST'
export const ON_CLICK_NEW_TICKET_SUCCESS = 'ON_CLICK_NEW_TICKET_SUCCESS'
export const ON_CLICK_NEW_TICKET_FAIL = 'ON_CLICK_NEW_TICKET_FAIL'

export const GET_ALL_GOALS_REQUEST = 'GET_ALL_GOALS_REQUEST'
export const GET_AaLL_GOALS_SUCCESS = 'GET_AaLL_GOALS_SUCCESS'
export const GET_ALL_GOALS_FAIL = 'GET_ALL_GOALS_FAIL'

export const ON_CLICK_NEW_GOAL_REQUEST = 'ON_CLICK_NEW_GOAL_REQUEST'
export const ON_CLICK_NEW_GOAL_SUCCESS = 'ON_CLICK_NEW_GOAL_SUCCESS'
export const ON_CLICK_NEW_GOAL_FAIL = 'ON_CLICK_NEW_GOAL_FAIL'

export const GET_CUSTOMER_TICKETS_REQUEST = 'GET_CUSTOMER_TICKETS_REQUEST'
export const GET_CUSTOMER_TICKETS_SUCCESS = 'GET_CUSTOMER_TICKETS_SUCCESS'
export const GET_CUSTOMER_TICKETS_FAIL = 'GET_CUSTOMER_TICKETS_FAIL'

export const SET_CUSTOMER_ID = 'SET_CUSTOMER_ID'
export const CHANGE_SELECTED_TICKET_ID = 'CHANGE_SELECTED_TICKET_ID'
