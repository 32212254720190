import traditionalsCustomerImg from '../content/images/Traditionals.png'
import pensionerCustomerImage from '../content/images/Pensioners.png'
import modernCustomerImage from '../content/images/ModernWomen.png'

export const GetCustomerDefaultImageByAge = (customer) => {
    let image = traditionalsCustomerImg;

    if (customer && customer.age) {
        if (customer.age > 60) {
            image = pensionerCustomerImage;
        } else if (customer.age < 35) {
            image = modernCustomerImage;
        }
    }

    return image;
}

export const GetAnthropometricFields = () => {
    return [
        { label: "Рост", id: "measurementheight", fieldName: "height" },
        { label: "Вес", id: "measurementweight", fieldName: "weight" },
        { label: "PS Пульс", id: "measurementpspulse", fieldName: "pspulse" },
        { label: "AD верхнее", id: "measurementadup", fieldName: "adup" },
        { label: "AD нижнее", id: "measurementaddown", fieldName: "addown" },
        { label: "Шея", id: "measurementneck", fieldName: "neck" },
        { label: "Грудь (вдох)", id: "measurementchestIn", fieldName: "chestIn" },
        { label: "Грудь (выдох)", id: "measurementchestOut", fieldName: "chestOut" },
        { label: "Правое плечо расслаблено", id: "measurementrightRelax", fieldName: "rightRelax" },
        { label: "Правое плечо напряжено", id: "measurementrightTense", fieldName: "rightTense" },
        { label: "Левое плечо расслаблено", id: "measurementleftRelax", fieldName: "leftRelax" },
        { label: "Левое плечо напряжено", id: "measurementleftTense", fieldName: "leftTense" },
        { label: "Предплечье левое", id: "measurementforearmLeft", fieldName: "forearmLeft" },
        { label: "Предплечье правое", id: "measurementforearmRight", fieldName: "forearmRight" },
        { label: "Талия", id: "measurementwaist", fieldName: "waist" },
        { label: "Живот", id: "measurementstomach", fieldName: "stomach" },
        { label: "Бедра", id: "measurementleg", fieldName: "leg" },
        { label: "Ягодицы", id: "measurementbuttocks", fieldName: "buttocks" },
        { label: "Бедро левое", id: "measurementlegLeft", fieldName: "legLeft" },
        { label: "Бедро правое", id: "measurementlegRight", fieldName: "legRight" },
        { label: "Голень левая", id: "measurementshinLeft", fieldName: "shinLeft" },
        { label: "Голень правая", id: "measurementshinRight", fieldName: "shinRight" },
        
        { label: "Жир", id: "measurementfat", fieldName: "fat" },
        { label: "Вес внутр. органов", id: "measurementinternalMass", fieldName: "internalMass" },
        { label: "Мышечная масса", id: "measurementmusculeMass", fieldName: "musculeMass" },
        { label: "Вода", id: "measurementWater", fieldName: "water" },
        { label: "Костная масса", id: "measurementbonesMass", fieldName: "bonesMass" },
        { label: "Уровень сжигаемых ккал", id: "measurementkkalBurn", fieldName: "kkalBurn" },
        { label: "Метаболический возраст", id: "measurementmetaAge", fieldName: "metaAge" },
        { label: "Уровень висцерального жира", id: "measurementvascFat", fieldName: "vascFat" },
        { label: "ИМТ", id: "measurementmassIndex", fieldName: "massIndex" },
        { label: "Идеальный вес", id: "measurementidealWeight", fieldName: "idealWeight" },
        { label: "Степень ожирения", id: "measurementfatStage", fieldName: "fatStage" },
    ];
}

export const getDiscounts = () => {
    return [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100]
};