import React from 'react'
import measurementsImg from '../../content/images/Measurements.png'
import { Grid, Button } from '@material-ui/core'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { changeMeasurementValue, saveNewMeasurement } from '../../actions/CustomerActions'
import Field from './Field'
import Loading from '../Shared/Loading'
import { GetAnthropometricFields } from '../../helpers/customer'
import Goals from '../Customer/Goals'
import { goToPage } from '../../actions/Route'

const Measurements = ({ customer, measurement, changeMeasurementValue, saveNewMeasurement, measurementLoading, goToPage }) => (
    <Grid container alignItems="center">
        <Grid item xs={3}>
            <img src={customer.image ? 'data:image/png;base64, ' + customer.image : measurementsImg} alt="Замеры" style={{ width: "96px" }} />
        </Grid>
        <Grid item xs={9}>
            <Grid container style={{ textAlign: "center" }}>
                <Grid item xs={12}>{customer.firstName}</Grid>
                <Grid item xs={12}>
                    <a href={"tel:+" + customer.phone}>
                        <strong>+{customer.phone}</strong>
                    </a>
                </Grid>
                <Goals goals={customer.goals} onClick={() => goToPage('/NewGoal')} />
            </Grid>
        </Grid>

        {GetAnthropometricFields().map((el) => (
            <Field label={el.label} value={measurement[el.fieldName]} id={el.id} fieldName={el.fieldName} changeMeasurementValue={changeMeasurementValue} key={el.id} />
        ))}

        <Button
            variant="outlined"
            style={{ margin: "5px" }}
            disabled={customer.id && !measurementLoading && Object.keys(measurement).some((m) => measurement[m])
                ? false : true}
            onClick={() => saveNewMeasurement(customer.id, measurement)}
        >
            Сохранить
        </Button>
        {measurementLoading && <Loading width={2} />}
    </Grid>
);

const mapStateToProps = state => ({
    customer: state.customer.customer,
    measurement: state.customer.measurement,
    measurementLoading: state.customer.measurementLoading,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    changeMeasurementValue,
    saveNewMeasurement,
    goToPage
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Measurements);