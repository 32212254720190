export const Add = (name, value) => {
    localStorage.setItem(name, value);
}

export const Get = (name) => {
    return localStorage.getItem(name);
}

export const Clear = () => {
    localStorage.clear();
}