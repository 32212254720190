import { createStore } from 'redux'
import { applyMiddleware, compose } from 'redux'
import reducers from '../reducers'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'react-router-redux'
import history from "./history";

export default function configureStore() {
    const logger = createLogger({
        collapsed: true,
        duration: true,
        timestamp: true,
        diff :true
    })

    const routingMiddleware = routerMiddleware(history);

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
        reducers,
        composeEnhancers(applyMiddleware(thunk, logger, routingMiddleware)))

    return store;
}