import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core'

const TreatmentInfo = ({ treatmentsList, showAllTreatmentsIfMany }) => {
    return treatmentsList.map((treatment, index) => {
        if (!showAllTreatmentsIfMany && index >= 5) {
            return null;
        }

        return <Grid container alignItems="center" key={index}>
            <Grid item xs={1} style={{
                minWidth: "28px",
                minHeight: "28px",
                backgroundColor: treatment.isAvailable ? "green" : "red",
                borderRadius: "50%",
                textAlign: "center",
                fontWeight: "bold",
                justifyContent: "center",
                flexDirection: "column",
                display: "flex" }}
            >
                {treatment.treatmentTypeTag}
            </Grid>
            <Grid item xs={11}>
                <strong>{treatment.tag}</strong>,
                <span>{treatment.availableTime}</span>
            </Grid>
        </Grid>
    })
}

const mapStateToProps = state => ({
    treatmentsList: state.main.treatmentsList,
    showAllTreatmentsIfMany: state.main.showAllTreatmentsIfMany
})

export default connect(mapStateToProps)(TreatmentInfo);