import api from '../constants/api'
import { fetchGet, fetchPost } from '../helpers/fetchHelper'
import {
    GET_TRAINER_IMAGE_REQUEST,
    GET_TRAINER_IMAGE_SUCCESS,
    GET_TRAINER_IMAGE_FAIL,

    DOWNLOAD_TRAINER_IMAGE_REQUEST,
    DOWNLOAD_TRAINER_IMAGE_SUCCESS,
    DOWNLOAD_TRAINER_IMAGE_FAIL,

    GET_TRAINER_DATA_REQUEST,
    GET_TRAINER_DATA_SUCCESS,
    GET_TRAINER_DATA_FAIL,

    GET_CURRENT_REVENUE_REQUEST,
    GET_CURRENT_REVENUE_SUCCESS,
    GET_CURRENT_REVENUE_FAIL,

    TOGGLE_MODAL_NEW_TRAINER_IMAGE,
    CHANGE_ONLY_MY_WORKING_DAYS
} from '../constants/Trainer'

const getTrainerImageRequest = () => ({ type: GET_TRAINER_IMAGE_REQUEST })
const getTrainerImageSuccess = (data) => ({ type: GET_TRAINER_IMAGE_SUCCESS, data })
const getTrainerImageFail = (error) => ({ type: GET_TRAINER_IMAGE_FAIL, error })

const getTrainerDataRequest = () => ({ type: GET_TRAINER_DATA_REQUEST })
const getTrainerDataSuccess = (data) => ({ type: GET_TRAINER_DATA_SUCCESS, data })
const getTrainerDataFail = (error) => ({ type: GET_TRAINER_DATA_FAIL, error })

const getCurrentRevenueRequest = () => ({ type: GET_CURRENT_REVENUE_REQUEST })
const getCurrentRevenueSuccess = (data) => ({ type: GET_CURRENT_REVENUE_SUCCESS, data })
const getCurrentRevenueFail = (error) => ({ type: GET_CURRENT_REVENUE_FAIL, error })

const downloadTrainerImageRequest = () => ({ type: DOWNLOAD_TRAINER_IMAGE_REQUEST })
const downloadTrainerImageSuccess = (file) => ({ type: DOWNLOAD_TRAINER_IMAGE_SUCCESS, file })
const downloadTrainerImageFail = (error) => ({ type: DOWNLOAD_TRAINER_IMAGE_FAIL, error })

export const toggleModalNewTrainerImage = () => ({ type: TOGGLE_MODAL_NEW_TRAINER_IMAGE })
export const changeOnlyMyWorkingDays = () => ({ type: CHANGE_ONLY_MY_WORKING_DAYS })

export const getTrainerImage = () => {
    return async (dispatch) => {
        dispatch(getTrainerImageRequest());

        await fetchGet(api.links.getTrainerImage())
            .then(result => result.json())
            .then(result => dispatch(getTrainerImageSuccess(result.data)))
            .catch((error) => {
                dispatch(getTrainerImageFail(error))
            })
    }
}

export const getTrainerData = () => {
    return async (dispatch) => {
        dispatch(getTrainerDataRequest());

        await fetchGet(api.links.getTrainerData())
            .then(result => result.json())
            .then(result => dispatch(getTrainerDataSuccess(result.data)))
            .catch((error) => {
                dispatch(getTrainerDataFail(error))
            })
    }
}

export const getCurrentRevenue = () => {
    return async (dispatch) => {
        dispatch(getCurrentRevenueRequest());

        await fetchGet(api.links.getCurrentRevenue())
            .then(result => result.json())
            .then(result => dispatch(getCurrentRevenueSuccess(result.data)))
            .catch((error) => {
                dispatch(getCurrentRevenueFail(error))
            })
    }
}

export const downloadTrainerImage = (trainerId, file) => {
    return async (dispatch) => {
        dispatch(downloadTrainerImageRequest());

        await fetchPost(api.links.downloadImage(), JSON.stringify({ entityId: trainerId, image: file, entityName: 'user' }))
            .then(() => {
                dispatch(downloadTrainerImageSuccess(file));
            })
            .catch((error) => {
                dispatch(downloadTrainerImageFail(error))
            })
    }
}