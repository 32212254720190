export const GET_TREATMENT_CONFIGS_REQUEST = 'GET_TREATMENT_CONFIGS_REQUEST'
export const GET_TREATMENT_CONFIGS_SUCCESS = 'GET_TREATMENT_CONFIGS_SUCCESS'
export const GET_TREATMENT_CONFIGS_FAIL = 'GET_TREATMENT_CONFIGS_FAIL'

export const SELECT_DATE = 'SELECT_DATE'
export const ADD_NEW_ITEM = 'ADD_NEW_ITEM'
export const CHANGE_TREATMENT_CONFIG_ID = 'CHANGE_TREATMENT_CONFIG_ID'
export const REMOVE_ITEM = 'REMOVE_ITEM'

export const CHECK_VISIT_REQUEST = 'CHECK_VISIT_REQUEST'
export const CHECK_VISIT_SUCCESS = 'CHECK_VISIT_SUCCESS'
export const CHECK_VISIT_FAIL = 'CHECK_VISIT_FAIL'

export const CREATE_VISIT_REQUEST = 'CREATE_VISIT_REQUEST'
export const CREATE_VISIT_SUCCESS = 'CREATE_VISIT_SUCCESS'
export const CREATE_VISIT_FAIL = 'CREATE_VISIT_FAIL'

export const CHECK_VISIT_TIMEOUT = 'CHECK_VISIT_TIMEOUT'
