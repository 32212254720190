import React from 'react'
import ticketsImg from '../../content/images/Tickets.png'
import scheduleImg from '../../content/images/Schedule.png'
import whatsApp from '../../content/images/WhatsApp.png'
import viber from '../../content/images/Viber.png'
import birthdayImg from '../../content/images/Birthday.png'
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { convertToMMDD } from '../../helpers/dateConverter'
import AnthropometricDetails from './AnthropometricDetails'
import { GetCustomerDefaultImageByAge } from '../../helpers/customer'
import { toggleModalNewImage, downloadCustomerImage } from '../../actions/CustomerActions'
import NewImageModal from '../Shared/NewImageModal';
import Loading from '../Shared/Loading'
import { goToPage } from '../../actions/Route'
import Goals from './Goals'

const CustomerDetails = ({ customer, showModalNewImage, toggleModalNewImage, downloadCustomerImage, isNewImageLoading, newImageError, goToPage }) => (
    <Grid container>
        <Grid item xs={6} style={{ textAlign: "center" }}>
            {isNewImageLoading
                ? <Loading />
                : <span>
                    <div className="roundImage" style={{ width: "128px", height: "128px", float: "none" }}>
                        <img src={customer.image ? 'data:image/png;base64, ' + customer.image : GetCustomerDefaultImageByAge(customer)} alt={customer.firstName} />
                    </div>
                    {customer.id &&
                        <span>
                            <i style={{ cursor: "pointer" }} onClick={() => toggleModalNewImage()}>Изменить</i>
                            {newImageError && <span><br />{newImageError}</span>}
                        </span>}
                </span>}
        </Grid>
        <Grid item xs={6}>
            <Grid container>
                <Grid item xs={12} style={{ paddingBottom: "12px" }}>
                    <strong>{customer.lastName} {customer.firstName}</strong>
                </Grid>
                <Grid item xs={12}>
                    Дата рождения:
                </Grid>
                <Grid item xs={12} style={{ backgroundColor: customer.isBirthdayToday ? "yellow" : "none" }}>
                    {customer.isBirthdayToday && <img src={birthdayImg} alt="Birthday" style={{ width: "24px" }} />}
                    {customer.birthday}
                </Grid>
                <Goals goals={customer.goals} onClick={() => goToPage('/NewGoal')} />
                <Grid item xs={12}>
                    <a href={"tel:+" + customer.phone}>
                        <strong style={{ backgroundColor: "lime" }}>+{customer.phone}</strong>
                    </a>
                    <a href={"whatsapp://send?phone=+" + customer.phone}>
                        <img src={whatsApp} alt="WhatsApp" style={{ width: "24px" }} />
                    </a>
                    <a href={"viber://chat?number=" + customer.phone}>
                        <img src={viber} alt="Viber" style={{ width: "24px" }} />
                    </a>
                </Grid>
            </Grid>
        </Grid>

        <Grid item xs={12}>
            <i style={{ cursor: "pointer" }} onClick={() => goToPage("/NewVisit")}>Добавить визит</i>
        </Grid>

        {customer.nextVisitDates.length !== 0 && <Grid item xs={12} style={{ textAlign: "center" }}>
            <strong>Следующий визит</strong>
        </Grid>}

        {customer.nextVisitDates.length !== 0 && <Grid item xs={12} style={{ textAlign: "center" }}>
            {customer.nextVisitDates.map(i => convertToMMDD(i)).join(', ')} <strong>+</strong>
        </Grid>}

        <Grid item xs={12}>
            <Grid container alignItems="center">
                <Grid item xs={3}>
                    <img src={ticketsImg} alt="Абонементы" style={{ width: "48px" }} />
                </Grid>
                <Grid item xs={9}>
                    <strong>Абонементы</strong>&nbsp;
                    <i style={{ cursor: "pointer" }} onClick={() => goToPage('/NewTicket')}>
                        (купить)
                    </i>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <span style={{ fontSize: "24px", fontWeight: "bold" }}>{customer.ticketsDetails.ticketsAmount}</span>&nbsp;
            <span>абонемент, тренировок</span>&nbsp;
            <span style={{ fontSize: "24px", fontWeight: "bold" }}>{customer.ticketsDetails.smartLeft}</span>&nbsp;
            <span>из</span>&nbsp;
            <span style={{ fontSize: "24px", fontWeight: "bold" }}>{customer.ticketsDetails.smartAmount}</span>
        </Grid>
        <Grid item xs={12}>Действует до: {customer.ticketsDetails.tillTo}</Grid>

        <Grid item xs={12}>
            <Grid container alignItems="center">
                <Grid item xs={3}>
                    <img src={scheduleImg} alt="Посещаемость" style={{ width: "48px" }} />
                </Grid>
                <Grid item xs={9}>
                    <strong>Посещаемость в месяц</strong>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <span>В этот</span>&nbsp;
            <span style={{ fontSize: "24px", fontWeight: "bold" }}>{customer.ticketsDetails.amountVisitsCurrentMonth}</span>&nbsp;
            <span>раз, в прошлый</span>&nbsp;
            <span style={{ fontSize: "24px", fontWeight: "bold" }}>{customer.ticketsDetails.amountVisitsLastMonth}</span>
            <span>, средняя в год</span>&nbsp;
            <span style={{ fontSize: "24px", fontWeight: "bold" }}>{customer.ticketsDetails.averageVisitsPerYear}</span>&nbsp;
        </Grid>

        {customer.comment && <Grid item xs={12} style={{ textAlign: "center", marginTop: "12px" }}>
            Комментарии
        </Grid>}

        <Grid item xs={12}>
            {customer.comment}
        </Grid>

        <AnthropometricDetails anthropometrics={customer.anthropometrics} />

        <NewImageModal
            showModalNewImage={showModalNewImage}
            toggleModalNewImage={toggleModalNewImage}
            entityId={customer.id}
            onSave={downloadCustomerImage} />
    </Grid>
)

const mapStateToProps = state => ({
    customer: state.customer.customer,
    showModalNewImage: state.customer.showModalNewImage,
    isNewImageLoading: state.customer.isNewImageLoading,
    newImageError: state.customer.newImageError
})

const mapDispatchToProps = dispatch => bindActionCreators({
    toggleModalNewImage,
    downloadCustomerImage,
    goToPage
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetails)