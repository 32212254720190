export const GET_TRAINER_IMAGE_REQUEST = 'GET_TRAINER_IMAGE_REQUEST'
export const GET_TRAINER_IMAGE_SUCCESS = 'GET_TRAINER_IMAGE_SUCCESS'
export const GET_TRAINER_IMAGE_FAIL = 'GET_TRAINER_IMAGE_FAIL'
export const TOGGLE_MODAL_NEW_TRAINER_IMAGE = 'TOGGLE_MODAL_NEW_TRAINER_IMAGE'
export const DOWNLOAD_TRAINER_IMAGE_REQUEST = 'DOWNLOAD_TRAINER_IMAGE_REQUEST'
export const DOWNLOAD_TRAINER_IMAGE_SUCCESS = 'DOWNLOAD_TRAINER_IMAGE_SUCCESS'
export const DOWNLOAD_TRAINER_IMAGE_FAIL = 'DOWNLOAD_TRAINER_IMAGE_FAIL'
export const GET_TRAINER_DATA_REQUEST = 'GET_TRAINER_DATA_REQUEST'
export const GET_TRAINER_DATA_SUCCESS = 'GET_TRAINER_DATA_SUCCESS'
export const GET_TRAINER_DATA_FAIL = 'GET_TRAINER_DATA_FAIL'
export const GET_CURRENT_REVENUE_REQUEST = 'GET_CURRENT_REVENUE_REQUEST'
export const GET_CURRENT_REVENUE_SUCCESS = 'GET_CURRENT_REVENUE_SUCCESS'
export const GET_CURRENT_REVENUE_FAIL = 'GET_CURRENT_REVENUE_FAIL'
export const CHANGE_ONLY_MY_WORKING_DAYS = 'CHANGE_ONLY_MY_WORKING_DAYS'
