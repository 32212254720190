import React from 'react'
import { Grid } from '@material-ui/core'
import measurementsImg from '../../content/images/Measurements.png'
import eventImg from '../../content/images/Events.png'
import scheduleImg from '../../content/images/Schedule.png'
import callsImg from '../../content/images/Calls.png'
import { goToPage } from '../../actions/Route'
import { connect } from 'react-redux'

const NavMenu = ({ dispatch }) => (
    <div>
        <Grid container justify="center" style={{ textAlign: "center" }}>
            <Grid item xs={3}>
                <Grid item>
                    <img
                        src={callsImg}
                        onClick={() => dispatch(goToPage('/'))}
                        style={{ width: "48px", height: "48px", cursor: "pointer" }}
                        alt='Звонки'
                    />
                </Grid>
                <Grid item>
                    <span onClick={() => dispatch(goToPage('/'))} style={{ cursor: "pointer" }}>
                        Главная
                    </span>
                </Grid>
            </Grid>

            <Grid item xs={3}>
                <Grid item>
                    <img
                        src={measurementsImg}
                        onClick={() => dispatch(goToPage('/'))}
                        style={{ width: "48px", height: "48px", cursor: "pointer" }}
                        alt='Органайзер'
                    />
                </Grid>
                <Grid item>
                    <span onClick={() => dispatch(goToPage('/'))} style={{ cursor: "pointer" }}>
                        Органайзер
                    </span>
                </Grid>
            </Grid>

            <Grid item xs={3}>
                <Grid item>
                    <img
                        src={eventImg}
                        onClick={() => dispatch(goToPage('/'))}
                        style={{ width: "48px", height: "48px", color: "red", cursor: "pointer" }}
                        alt='Запись'
                    />
                </Grid>
                <Grid item>
                    <span onClick={() => dispatch(goToPage('/'))} style={{ cursor: "pointer" }}>
                        Запись
                    </span>
                </Grid>
            </Grid>

            <Grid item xs={3}>
                <Grid item>
                    <img
                        src={scheduleImg}
                        onClick={() => dispatch(goToPage('/Schedule'))}
                        style={{ width: "48px", height: "48px", cursor: "pointer" }}
                        alt='Расписание'
                    />
                </Grid>
                <Grid item>
                    <span onClick={() => dispatch(goToPage('/Schedule'))} style={{ cursor: "pointer" }}>
                        Расписание
                    </span>
                </Grid>
            </Grid>
        </Grid>
    </div>
);

export default connect(null)(NavMenu);