import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { Grid, FormControl, Select, MenuItem, InputLabel, Button } from "@material-ui/core"
import { getAllGoals, changeSelectedGoalId, onClickNewGoal } from '../../actions/CustomerActions'
import Loading from '../Shared/Loading';

class NewGoal extends Component {
    UNSAFE_componentWillMount() {
        this.props.getAllGoals(this.props.customer.id);
    }

    render() {
        const { customer, isLoading, errorMessage, goalsList, selectedGoalId,
            newGoalRequestLoading, newGoalRequestImageError, changeSelectedGoalId, onClickNewGoal } = this.props;

        if (isLoading)
            return <Loading />

        if (errorMessage)
            return <Grid item xs={12}>{errorMessage}</Grid>

        return <Grid container>
            <Grid item xs={12}>{customer.lastName} {customer.firstName}</Grid>
            <Grid item xs={12}>Выберите цель:</Grid>
            <Grid item xs={12}>
                <FormControl style={{ width: '100%' }}>
                    <InputLabel id="goalLabel">Цель</InputLabel>
                    <Select
                        labelId="goalLabel"
                        value={selectedGoalId}
                        onChange={(e) => changeSelectedGoalId(e.target.value)}
                    >
                        <MenuItem key="-1" value="" disabled>Не выбрано</MenuItem>
                        {goalsList.map((goal, index) => (
                            <MenuItem key={index} value={goal.id}>{goal.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            {newGoalRequestLoading
                ? <Loading />
                : <Grid item xs={12}>
                    <Button
                        variant="outlined"
                        style={{ margin: "5px" }}
                        disabled={selectedGoalId === ''}
                        onClick={() => onClickNewGoal(customer.id, selectedGoalId)}
                    >
                        Сохранить
                    </Button>
                </Grid>}

            {newGoalRequestImageError.length >= 0 && <Grid item xs={12}>{newGoalRequestImageError}</Grid>}
        </Grid>
    }
}

const mapStateToProps = state => ({
    customer: state.customer.customer,
    isLoading: state.customer.allGoalsLoading,
    errorMessage: state.customer.allGoalsRequestErrorMessage,
    goalsList: state.customer.goalsList,
    selectedGoalId: state.customer.selectedGoalId,
    newGoalRequestLoading: state.customer.newGoalRequestLoading,
    newGoalRequestImageError: state.customer.newGoalRequestImageError,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    getAllGoals,
    changeSelectedGoalId,
    onClickNewGoal
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(NewGoal);