import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import main from './main'
import customer from './customer'
import trainer from './trainer'
import login from './login'
import visit from './visit'

export default combineReducers({
    login,
    main,
    customer,
    trainer,
    visit,
    router: routerReducer
})