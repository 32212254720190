import React from 'react';
import { Grid } from '@material-ui/core';
import TreatmentsContainer from './TreatmentsContainer'
import CustomersContainer from './CustomersContainer'
import HeadMain from './HeadMain'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { toggleCustomers, toggleTreatments } from '../../actions/MainActions'
import ToggleElement from './ToggleElement'

const Main = ({ showAllCustomersIfMany, showAllTreatmentsIfMany, toggleCustomers, toggleTreatments, amountCustomers, amountTreatments }) => (
    <Grid container>
        <HeadMain />

        <ToggleElement entityName="Клиенты" showAllIfMany={showAllCustomersIfMany} toggleAction={toggleCustomers} amount={amountCustomers} />
        <CustomersContainer />

        <ToggleElement entityName="Услуги" showAllIfMany={showAllTreatmentsIfMany} toggleAction={toggleTreatments} amount={amountTreatments} />
        <TreatmentsContainer />
    </Grid>
);

const mapStateToProps = state => ({
    showAllCustomersIfMany: state.main.showAllCustomersIfMany,
    showAllTreatmentsIfMany: state.main.showAllTreatmentsIfMany,
    amountCustomers: state.main.customersList.length,
    amountTreatments: state.main.treatmentsList.length,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    toggleCustomers,
    toggleTreatments
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Main);