import {
    ON_CLICK_CUSTOMER_REQUEST,
    ON_CLICK_CUSTOMER_SUCCESS,
    ON_CLICK_CUSTOMER_FAIL,

    CHANGE_MEASUREMENT_VALUE,

    SAVE_NEW_MEASUREMENT_REQUEST,
    SAVE_NEW_MEASUREMENT_SUCCESS,
    SAVE_NEW_MEASUREMENT_FAIL,

    DOWNLOAD_CUSTOMER_IMAGE_REQUEST,
    DOWNLOAD_CUSTOMER_IMAGE_SUCCESS,
    DOWNLOAD_CUSTOMER_IMAGE_FAIL,

    GET_TICKET_TYPES_REQUEST,
    GET_TICKET_TYPES_SUCCESS,
    GET_TICKET_TYPES_FAIL,

    ON_CLICK_NEW_TICKET_REQUEST,
    ON_CLICK_NEW_TICKET_SUCCESS,
    ON_CLICK_NEW_TICKET_FAIL,

    TOGGLE_MODAL_NEW_IMAGE,
    CHANGE_SELECTE_TtICKET_TYPE_ID,
    CHANGE_SELECTED_DISCOUNT_NEW_TICKET,
    SET_CUSTOMER_ID,
    CHANGE_SELECTED_GOAL_ID,

    GET_ALL_GOALS_REQUEST,
    GET_AaLL_GOALS_SUCCESS,
    GET_ALL_GOALS_FAIL,

    ON_CLICK_NEW_GOAL_REQUEST,
    ON_CLICK_NEW_GOAL_SUCCESS,
    ON_CLICK_NEW_GOAL_FAIL,

    GET_CUSTOMER_TICKETS_REQUEST,
    GET_CUSTOMER_TICKETS_SUCCESS,
    GET_CUSTOMER_TICKETS_FAIL,
    CHANGE_SELECTED_TICKET_ID
} from '../constants/Customer'
import { CUSTOMER } from '../constants/LocalStorageConstants'
import { GET_INITIAL_STATE } from '../constants/Index'
import {
    Add as AddToLocalStorage,
    Get as GetValueFromLocalStorage
} from '../helpers/localStorageHelper'

const initialState = {
    customer: {
        firstName: "",
        goals: [],
        nextVisitDates: [],
        anthropometrics: [],
        ticketsDetails: {}
    },
    measurement: {
        height: "",
        weight: "",
        pspulse: "",
        adup: "",
        addown: "",
        neck: "",
        chestIn: "",
        chestOut: "",
        rightRelax: "",
        rightTense: "",
        leftRelax: "",
        leftTense: "",
        forearmLeft: "",
        forearmRight: "",
        waist: "",
        stomach: "",
        leg: "",
        buttocks: "",
        legLeft: "",
        legRight: "",
        shinLeft: "",
        shinRight: "",
        fat: "",
        internalMass: "",
        musculeMass: "",
        water: "",
        bonesMass: "",
        kkalBurn: "",
        metaAge: "",
        vascFat: "",
        massIndex: "",
        idealWeight: "",
        fatStage: "",
    },
    measurementLoading: false,
    showModalNewImage: false,
    isNewImageLoading: false,
    newImageError: "",

    ticketTypeLoading: false,
    ticketTypeRequestErrorMessage: '',
    ticketTypesList: [],
    selectedTicketTypeId: '',
    selectedDiscountNewTicket: 0,

    newTicketRequestLoading: false,
    newTicketRequestImageError: '',

    allGoalsLoading: false,
    goalsList: [],
    selectedGoalId: '',
    allGoalsRequestErrorMessage: '',

    newGoalRequestLoading: false,
    newGoalRequestImageError: '',

    customerTicketsRequestLoading: false,
    customerTicketsErrorMessage: '',
    selectedTicketId: '',
    ticketsList: [],
}

const handler = {
    [GET_INITIAL_STATE]: (state) => {
        var customerLS = JSON.parse(GetValueFromLocalStorage(CUSTOMER))
        if (customerLS) {
            return { ...state, customer: customerLS }
        }
        return { ...state }
    },

    [ON_CLICK_CUSTOMER_REQUEST]: (state) => ({
        ...state,
        customerLoading: true
    }),
    [ON_CLICK_CUSTOMER_SUCCESS]: (state, action) => {
        AddToLocalStorage(CUSTOMER, JSON.stringify(action.data))
        return {
            ...state,
            customer: action.data,
            customerLoading: false
        }
    },
    [ON_CLICK_CUSTOMER_FAIL]: (state, action) => ({
        ...state,
        customerLoading: false,
        requestErrorMessage: action.error
    }),
    [CHANGE_MEASUREMENT_VALUE]: (state, action) => ({
        ...state,
        measurement: {
            ...state.measurement,
            [action.field]: action.text
        }
    }),
    [SAVE_NEW_MEASUREMENT_REQUEST]: (state) => ({
        ...state,
        measurementLoading: true,
    }),
    [SAVE_NEW_MEASUREMENT_SUCCESS]: (state) => ({
        ...state,
        measurement: {
            water: "",
            weight: "",
            fat: "",
            massIndex: ""
        },
        measurementLoading: false,
    }),
    [SAVE_NEW_MEASUREMENT_FAIL]: (state, action) => ({
        ...state,
        measurementLoading: false,
        messageError: action.error
    }),
    [DOWNLOAD_CUSTOMER_IMAGE_REQUEST]: (state) => ({
        ...state,
        isNewImageLoading: true
    }),
    [DOWNLOAD_CUSTOMER_IMAGE_SUCCESS]: (state) => ({
        ...state,
        isNewImageLoading: false,
        newImageError: ""
    }),
    [DOWNLOAD_CUSTOMER_IMAGE_FAIL]: (state, action) => ({
        ...state,
        isNewImageLoading: false,
        newImageError: action.error
    }),
    [TOGGLE_MODAL_NEW_IMAGE]: (state) => ({
        ...state,
        showModalNewImage: !state.showModalNewImage
    }),
    [GET_TICKET_TYPES_REQUEST]: (state) => ({
        ...state,
        ticketTypeLoading: true
    }),
    [GET_TICKET_TYPES_SUCCESS]: (state, action) => ({
        ...state,
        ticketTypesList: action.data,
        ticketTypeLoading: false,
        selectedTicketTypeId: '',
        selectedDiscountNewTicket: 0
    }),
    [GET_TICKET_TYPES_FAIL]: (state, action) => ({
        ...state,
        ticketTypeLoading: false,
        ticketTypeRequestErrorMessage: action.error
    }),
    [CHANGE_SELECTE_TtICKET_TYPE_ID]: (state, action) => ({
        ...state,
        selectedTicketTypeId: action.id
    }),
    [CHANGE_SELECTED_DISCOUNT_NEW_TICKET]: (state, action) => ({
        ...state,
        selectedDiscountNewTicket: action.discount
    }),

    [ON_CLICK_NEW_TICKET_REQUEST]: (state) => ({
        ...state,
        newTicketRequestLoading: true
    }),
    [ON_CLICK_NEW_TICKET_SUCCESS]: (state) => ({
        ...state,
        newTicketRequestLoading: false,
        newTicketRequestImageError: ""
    }),
    [ON_CLICK_NEW_TICKET_FAIL]: (state, action) => ({
        ...state,
        newTicketRequestLoading: false,
        newTicketRequestImageError: action.error
    }),

    [SET_CUSTOMER_ID]: (state, action) => ({
        ...state,
        customer: {
            ...state.customer,
            id: action.customerId
        }
    }),
    [GET_ALL_GOALS_REQUEST]: (state) => ({
        ...state,
        allGoalsLoading: true
    }),
    [GET_AaLL_GOALS_SUCCESS]: (state, action) => ({
        ...state,
        goalsList: action.data,
        allGoalsLoading: false,
        selectedGoalId: ''
    }),
    [GET_ALL_GOALS_FAIL]: (state, action) => ({
        ...state,
        allGoalsLoading: false,
        allGoalsRequestErrorMessage: action.error
    }),
    [CHANGE_SELECTED_GOAL_ID]: (state, action) => ({
        ...state,
        selectedGoalId: action.id
    }),

    [ON_CLICK_NEW_GOAL_REQUEST]: (state) => ({
        ...state,
        newGoalRequestLoading: true
    }),
    [ON_CLICK_NEW_GOAL_SUCCESS]: (state) => ({
        ...state,
        newGoalRequestLoading: false,
        newGoalRequestImageError: ""
    }),
    [ON_CLICK_NEW_GOAL_FAIL]: (state, action) => ({
        ...state,
        newGoalRequestLoading: false,
        newGoalRequestImageError: action.error
    }),
    [GET_CUSTOMER_TICKETS_REQUEST]: (state) => ({
        ...state,
        customerTicketsRequestLoading: true
    }),
    [GET_CUSTOMER_TICKETS_SUCCESS]: (state, action) => ({
        ...state,
        ticketsList: action.data,
        selectedTicketId: action.data.length > 0 ? action.data[0].id : '',
        customerTicketsRequestLoading: false,
    }),
    [GET_CUSTOMER_TICKETS_FAIL]: (state, action) => ({
        ...state,
        customerTicketsRequestLoading: false,
        customerTicketsErrorMessage: action.error
    }),
    [CHANGE_SELECTED_TICKET_ID]: (state, action) => ({
        ...state,
        selectedTicketId: action.id
    })
}

export default function main(state = initialState, action) {
    if (handler[action.type]) {
        return handler[action.type](state, action);
    }
    return state;
}