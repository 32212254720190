import { GET_INITIAL_STATE } from '../constants/Index'
import {
    GET_TRAINER_IMAGE_REQUEST,
    GET_TRAINER_IMAGE_SUCCESS,
    GET_TRAINER_IMAGE_FAIL,

    TOGGLE_MODAL_NEW_TRAINER_IMAGE,
    CHANGE_ONLY_MY_WORKING_DAYS,

    DOWNLOAD_TRAINER_IMAGE_REQUEST,
    DOWNLOAD_TRAINER_IMAGE_SUCCESS,
    DOWNLOAD_TRAINER_IMAGE_FAIL,

    GET_TRAINER_DATA_REQUEST,
    GET_TRAINER_DATA_SUCCESS,
    GET_TRAINER_DATA_FAIL,

    GET_CURRENT_REVENUE_REQUEST,
    GET_CURRENT_REVENUE_SUCCESS,
    GET_CURRENT_REVENUE_FAIL,
} from '../constants/Trainer'

const initialState = {
    trainer: {
        name: '',
        image: '',
        amountWorkingDays: 0,
        avgCustomersInDayInMyWorkingDays: 0,
        amountAnthropometricsMonth: 0,
        amountMyAnthropometricsMonth: 0,
        amountConsultationsMonth: 0,
        amountMyConsultationsMonth: 0,
        avgCustomersInDayMonth: 0
    },
    isImageLoading: false,
    imageErrorMessage: '',
    showModalNewTrainerImage: false,
    newImageError: '',
    isNewImageLoading: false,
    isDataLoading: false,
    dataErrorMessage: '',
    isCurrentRevenueLoading: false,
    currentRevenueErrorMessage: '',
    currentRevenue: 0,
    currentMyRevenue: 0,
    planMonthCurrent: '',
    onlyMyWorkingDays: false
}

const handler = {
    [GET_INITIAL_STATE]: (state) => ({ ...state }),

    [GET_TRAINER_IMAGE_REQUEST]: (state) => ({
        ...state,
        isImageLoading: true
    }),
    [GET_TRAINER_IMAGE_SUCCESS]: (state, action) => ({
        ...state,
        trainer: {
            ...state.trainer,
            id: action.data.id,
            image: action.data.image,
            name: action.data.name
        },
        isImageLoading: false
    }),
    [GET_TRAINER_IMAGE_FAIL]: (state, action) => ({
        ...state,
        imageErrorMessage: action.error,
        isImageLoading: false
    }),
    [TOGGLE_MODAL_NEW_TRAINER_IMAGE]: (state) => ({
        ...state,
        showModalNewTrainerImage: !state.showModalNewTrainerImage
    }),
    [DOWNLOAD_TRAINER_IMAGE_REQUEST]: (state) => ({
        ...state,
        isNewImageLoading: true
    }),
    [DOWNLOAD_TRAINER_IMAGE_SUCCESS]: (state, action) => ({
        ...state,
        isNewImageLoading: false, 
        newImageError: "",
        trainer: {
            ...state.trainer,
            image: action.file
        }
    }),
    [DOWNLOAD_TRAINER_IMAGE_FAIL]: (state, action) => ({
        ...state,
        isNewImageLoading: false,
        newImageError: action.error
    }),
    [GET_TRAINER_DATA_REQUEST]: (state) => ({
        ...state,
        isDataLoading: true
    }),
    [GET_TRAINER_DATA_SUCCESS]: (state, action) => ({
        ...state,
        trainer: {
            ...state.trainer,
            id: action.data.id,
            amountWorkingDays: action.data.amountWorkingDays,
            avgCustomersInDayInMyWorkingDays: action.data.avgCustomersInDayInMyWorkingDays,
            amountAnthropometricsMonth: action.data.amountAnthropometricsMonth,
            amountMyAnthropometricsMonth: action.data.amountMyAnthropometricsMonth,
            amountConsultationsMonth: action.data.amountConsultationsMonth,
            amountMyConsultationsMonth: action.data.amountMyConsultationsMonth,
            avgCustomersInDayMonth: action.data.avgCustomersInDayMonth
        },
        planMonthCurrent: action.data.planMonthCurrent,
        isDataLoading: false
    }),
    [GET_TRAINER_DATA_FAIL]: (state, action) => ({
        ...state,
        dataErrorMessage: action.error,
        isDataLoading: false
    }),
    [GET_CURRENT_REVENUE_REQUEST]: (state) => ({
        ...state,
        isCurrentRevenueLoading: true
    }),
    [GET_CURRENT_REVENUE_SUCCESS]: (state, action) => ({
        ...state,
        currentRevenue: action.data.month,
        currentMyRevenue: action.data.my,
        isCurrentRevenueLoading: false
    }),
    [GET_CURRENT_REVENUE_FAIL]: (state, action) => ({
        ...state,
        currentRevenueErrorMessage: action.error,
        isCurrentRevenueLoading: false
    }),
    [CHANGE_ONLY_MY_WORKING_DAYS]: state => ({
        ...state,
        onlyMyWorkingDays: !state.onlyMyWorkingDays
    }),
}

export default function main(state = initialState, action) {
    if (handler[action.type]) {
        return handler[action.type](state, action);
    }
    return state;
}