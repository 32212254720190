import {
    CHANGE_MEASUREMENT_VALUE,

    SAVE_NEW_MEASUREMENT_REQUEST,
    SAVE_NEW_MEASUREMENT_SUCCESS,
    SAVE_NEW_MEASUREMENT_FAIL,

    DOWNLOAD_CUSTOMER_IMAGE_REQUEST,
    DOWNLOAD_CUSTOMER_IMAGE_SUCCESS,
    DOWNLOAD_CUSTOMER_IMAGE_FAIL,

    GET_TICKET_TYPES_REQUEST,
    GET_TICKET_TYPES_SUCCESS,
    GET_TICKET_TYPES_FAIL,

    ON_CLICK_NEW_TICKET_REQUEST,
    ON_CLICK_NEW_TICKET_SUCCESS,
    ON_CLICK_NEW_TICKET_FAIL,

    ON_CLICK_CUSTOMER_REQUEST,
    ON_CLICK_CUSTOMER_SUCCESS,
    ON_CLICK_CUSTOMER_FAIL,

    TOGGLE_MODAL_NEW_IMAGE,
    CHANGE_SELECTE_TtICKET_TYPE_ID,
    CHANGE_SELECTED_DISCOUNT_NEW_TICKET,
    CHANGE_SELECTED_GOAL_ID,

    GET_ALL_GOALS_REQUEST,
    GET_AaLL_GOALS_SUCCESS,
    GET_ALL_GOALS_FAIL,

    ON_CLICK_NEW_GOAL_REQUEST,
    ON_CLICK_NEW_GOAL_SUCCESS,
    ON_CLICK_NEW_GOAL_FAIL,

    GET_CUSTOMER_TICKETS_REQUEST,
    GET_CUSTOMER_TICKETS_SUCCESS,
    GET_CUSTOMER_TICKETS_FAIL,
} from '../constants/Customer'
import api from '../constants/api'
import { goToPage } from './Route'
import { fetchPost, fetchGet } from '../helpers/fetchHelper'

const saveNewMeasurementRequest = () => ({ type: SAVE_NEW_MEASUREMENT_REQUEST });
const saveNewMeasurementSuccess = () => ({ type: SAVE_NEW_MEASUREMENT_SUCCESS });
const saveNewMeasurementFail = (error) => ({ type: SAVE_NEW_MEASUREMENT_FAIL, error });

const downloadCustomerImageRequest = () => ({ type: DOWNLOAD_CUSTOMER_IMAGE_REQUEST });
const downloadCustomerImageSuccess = () => ({ type: DOWNLOAD_CUSTOMER_IMAGE_SUCCESS });
const downloadCustomerImageFail = (error) => ({ type: DOWNLOAD_CUSTOMER_IMAGE_FAIL, error });

const getTicketTypesRequest = () => ({ type: GET_TICKET_TYPES_REQUEST });
const getTicketTypesSuccess = (data) => ({ type: GET_TICKET_TYPES_SUCCESS, data });
const getTicketTypesFail = (error) => ({ type: GET_TICKET_TYPES_FAIL, error });

const onClickNewTicketRequest = () => ({ type: ON_CLICK_NEW_TICKET_REQUEST });
const onClickNewTicketSuccess = () => ({ type: ON_CLICK_NEW_TICKET_SUCCESS });
const onClickNewTicketFail = (error) => ({ type: ON_CLICK_NEW_TICKET_FAIL, error });

const onClickCustomerRequest = () => ({ type: ON_CLICK_CUSTOMER_REQUEST })
const onClickCustomerSuccess = (data) => ({ type: ON_CLICK_CUSTOMER_SUCCESS, data });
const onClickCustomerFail = (error) => ({ type: ON_CLICK_CUSTOMER_FAIL, error });

const getAllGoalsRequest = () => ({ type: GET_ALL_GOALS_REQUEST });
const getAllGoalsSuccess = (data) => ({ type: GET_AaLL_GOALS_SUCCESS, data });
const getAllGoalsFail = (error) => ({ type: GET_ALL_GOALS_FAIL, error });

const onClickNewGoalRequest = () => ({ type: ON_CLICK_NEW_GOAL_REQUEST });
const onClickNewGoalSuccess = () => ({ type: ON_CLICK_NEW_GOAL_SUCCESS });
const onClickNewGoalFail = (error) => ({ type: ON_CLICK_NEW_GOAL_FAIL, error });

const getCustomerTicketsRequest = () => ({ type: GET_CUSTOMER_TICKETS_REQUEST });
const getCustomerTicketsSuccess = (data) => ({ type: GET_CUSTOMER_TICKETS_SUCCESS, data });
const getCustomerTicketsFail = (error) => ({ type: GET_CUSTOMER_TICKETS_FAIL, error });

export const changeMeasurementValue = (field, text) => ({ type: CHANGE_MEASUREMENT_VALUE, field, text });
export const toggleModalNewImage = () => ({ type: TOGGLE_MODAL_NEW_IMAGE });
export const changeSelectedTicketTypeId = (id) => ({ type: CHANGE_SELECTE_TtICKET_TYPE_ID, id });
export const changeSelectedDiscountNewTicket = (discount) => ({ type: CHANGE_SELECTED_DISCOUNT_NEW_TICKET, discount });
export const changeSelectedGoalId = (id) => ({ type: CHANGE_SELECTED_GOAL_ID, id });

export const saveNewMeasurement = (customerId, measurement) => {
    return async (dispatch) => {
        dispatch(saveNewMeasurementRequest());

        await fetchPost(api.links.addMeasurement(), JSON.stringify({ customerId, Anthropometric: measurement }))
            .then(() => {
                dispatch(saveNewMeasurementSuccess());
                dispatch(goToPage('Customer'));
            })
            .catch((error) => {
                dispatch(saveNewMeasurementFail(error))
            })
    }
}

export const downloadCustomerImage = (customerId, file) => {
    return async (dispatch) => {
        dispatch(downloadCustomerImageRequest());

        await fetchPost(api.links.downloadImage(), JSON.stringify({ entityId: customerId, image: file, entityName: 'customer' }))
            .then(() => {
                dispatch(downloadCustomerImageSuccess());
                dispatch(goToPage('Customer'));
            })
            .catch((error) => {
                dispatch(downloadCustomerImageFail(error))
            })
    }
}

export const getTicketTypes = () => {
    return async (dispatch) => {
        dispatch(getTicketTypesRequest());

        await fetchGet(api.links.getTicketTypes())
            .then(result => result.json())
            .then(result => dispatch(getTicketTypesSuccess(result.data)))
            .catch((error) => {
                dispatch(getTicketTypesFail(error))
            })
    }
}

export const onClickNewTicket = (customerId, ticketTypeId, discount) => {
    return async (dispatch) => {
        dispatch(onClickNewTicketRequest());

        await fetchPost(api.links.onClickNewTicket(), JSON.stringify({ customerId: customerId, ticketTypeId: ticketTypeId, discount: discount }))
            .then(() => {
                dispatch(onClickNewTicketSuccess());
                dispatch(goToPage('Customer'));
            })
            .catch((error) => {
                dispatch(onClickNewTicketFail(error))
            })
    }
}

export const onClickNewGoal = (customerId, goalId) => {
    return async (dispatch) => {
        dispatch(onClickNewGoalRequest());

        await fetchPost(api.links.onClickNewGoal(), JSON.stringify({ customerId: customerId, goalId: goalId }))
            .then(() => {
                dispatch(onClickNewGoalSuccess());
                dispatch(goToPage('Customer'));
            })
            .catch((error) => {
                dispatch(onClickNewGoalFail(error))
            })
    }
}

export const onClickCustomer = (customerId) => {
    return async (dispatch) => {
        dispatch(onClickCustomerRequest());
        
        await fetchGet(api.links.getCustomerById(customerId))
            .then(result => result.json())
            .then(result => dispatch(onClickCustomerSuccess(result.data)))
            .catch((error) => {
                dispatch(onClickCustomerFail(error))
            })
    }
}

export const getAllGoals = (customerId) => {
    return async (dispatch) => {
        dispatch(getAllGoalsRequest());

        await fetchGet(api.links.getAllGoals(customerId))
            .then(result => result.json())
            .then(result => dispatch(getAllGoalsSuccess(result.data)))
            .catch((error) => {
                dispatch(getAllGoalsFail(error))
            })
    }
}

export const getCustomerTickets = (customerId) => {
    return async (dispatch) => {
        dispatch(getCustomerTicketsRequest());

        await fetchGet(api.links.getCustomerTickets(customerId))
            .then(result => result.json())
            .then(result => dispatch(getCustomerTicketsSuccess(result.data)))
            .catch((error) => {
                dispatch(getCustomerTicketsFail(error))
            })
    }
}