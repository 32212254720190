import React from 'react'
import { Grid, TextField } from '@material-ui/core'

const Field = ({ id, label, value, fieldName, changeMeasurementValue }) => (
    <Grid item xs={12}>
        <TextField
            fullWidth
            id={id}
            label={label}
            value={value}
            type="number"
            onChange={(e) => changeMeasurementValue(fieldName, e.target.value)}
        />
    </Grid>
)

export default Field;