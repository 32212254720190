import React from 'react'
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel';

const NewItem = ({ allTreatmentsConfigs, item, index, changeTreatmentConfigId, removeItem }) => (
    <Grid container>
        <Grid item xs={11}>
            <FormControl style={{ width: '100%' }}>
                <InputLabel id={"allTreatmentsConfigsLabel" + index}>Услуга {index + 1}</InputLabel>
                <Select
                    labelId={"allTreatmentsConfigsLabel" + index}
                    value={item.id}
                    onChange={(e) => changeTreatmentConfigId(e.target.value, index)}
                >
                    <MenuItem key="-1" value="" disabled>Не выбрано</MenuItem>
                    {allTreatmentsConfigs.map((treatmentsConfig, indexTC) => (
                        <MenuItem key={indexTC} value={treatmentsConfig.id}>{treatmentsConfig.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
        <Grid item xs={1}>
            <CancelIcon style={{ cursor: "pointer" }} onClick={() => removeItem(index)} />
        </Grid>
    </Grid>
)

export default NewItem;