import React from 'react'
import { Grid, Paper, TableCell, TableBody, TableRow, Table, TableHead, TableContainer } from '@material-ui/core';
import measurementsImg from '../../content/images/Measurements.png'
import { convertToMMDD } from '../../helpers/dateConverter'
import { goToPage } from '../../actions/Route'
import { connect } from 'react-redux';
import AnthropometricTableRow from './AnthropometricTableRow'
import { GetAnthropometricFields } from '../../helpers/customer'

const AnthropometricDetails = ({ anthropometrics, dispatch }) => (
    <Grid container>
        <Grid item xs={12}>
            <Grid container alignItems="center">
                <Grid item xs={3}>
                    <img src={measurementsImg} alt="Замеры" style={{ width: "48px" }} />
                </Grid>
                <Grid item xs={9}>
                    <strong>
                        <span>Замеры,</span>&nbsp;
                        <span
                            style={{ backgroundColor: "yellow", cursor: "pointer" }}
                            onClick={() => dispatch(goToPage('/Measurements'))}
                        >
                            сделать >
                        </span>
                    </strong>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <TableContainer component={Paper} style={{ height: "250px" }}>
                <Table aria-label="simple table" size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Дата</TableCell>
                            {anthropometrics.map((a, index) =>
                                <TableCell align="right" key={index}>{convertToMMDD(a.createdOn)}</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {GetAnthropometricFields().map((el) => (
                            <AnthropometricTableRow name={el.label} anthropometrics={anthropometrics} value={el.fieldName} key={el.id} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    </Grid>
)

export default connect()(AnthropometricDetails);