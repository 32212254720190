import api from '../constants/api'
import { fetchGet, fetchPost } from '../helpers/fetchHelper'
import {
    GET_TREATMENT_CONFIGS_REQUEST,
    GET_TREATMENT_CONFIGS_SUCCESS,
    GET_TREATMENT_CONFIGS_FAIL,

    CHECK_VISIT_REQUEST,
    CHECK_VISIT_SUCCESS,
    CHECK_VISIT_FAIL,

    CREATE_VISIT_REQUEST,
    CREATE_VISIT_SUCCESS,
    CREATE_VISIT_FAIL,

    CHECK_VISIT_TIMEOUT,
    SELECT_DATE,
    ADD_NEW_ITEM,
    CHANGE_TREATMENT_CONFIG_ID,
    REMOVE_ITEM,
} from '../constants/Visit'
import { CHANGE_SELECTED_TICKET_ID } from '../constants/Customer'
import { goToPage } from './Route'

const getTreatmentConfigsRequest = () => ({ type: GET_TREATMENT_CONFIGS_REQUEST });
const getTreatmentConfigsSuccess = (data) => ({ type: GET_TREATMENT_CONFIGS_SUCCESS, data });
const getTreatmentConfigsFail = (error) => ({ type: GET_TREATMENT_CONFIGS_FAIL, error });

const checkVisitRequest = () => ({ type: CHECK_VISIT_REQUEST });
const checkVisitSuccess = (data) => ({ type: CHECK_VISIT_SUCCESS, data });
const checkVisitFail = (error) => ({ type: CHECK_VISIT_FAIL, error });

const createVisitRequest = () => ({ type: CREATE_VISIT_REQUEST });
const createVisitSuccess = () => ({ type: CREATE_VISIT_SUCCESS });
const createVisitFail = (error) => ({ type: CREATE_VISIT_FAIL, error });

const checkVisitTimeOut = () => ({ type: CHECK_VISIT_TIMEOUT });

export const selectDate = (date) => ({ type: SELECT_DATE, date })
export const addNewItem = () => ({ type: ADD_NEW_ITEM })
export const changeTreatmentConfigId = (id, index) => ({ type: CHANGE_TREATMENT_CONFIG_ID, id, index })
export const removeItem = (index) => ({ type: REMOVE_ITEM, index })
export const changeSelectedTicketId = (id) => ({ type: CHANGE_SELECTED_TICKET_ID, id })

export const getTreatmentConfigs = () => {
    return async (dispatch) => {
        dispatch(getTreatmentConfigsRequest());

        await fetchGet(api.links.getTreatmentConfigs())
            .then(result => result.json())
            .then(result => dispatch(getTreatmentConfigsSuccess(result.data)))
            .catch((error) => {
                dispatch(getTreatmentConfigsFail(error))
            })
    }
}

export const checkVisit = (customerId, ticketId, date, selectedItems) => {
    return async (dispatch) => {
        dispatch(checkVisitRequest());

        const utcDate = new Date(date);
        utcDate.setMinutes(utcDate.getMinutes() - utcDate.getTimezoneOffset());

        await fetchPost(api.links.checkVisit(), JSON.stringify({ customerId, ticketId, date: utcDate, selectedItems: selectedItems }))
            .then(result => result.json())
            .then(result => dispatch(checkVisitSuccess(result)))
            .catch((error) => {
                dispatch(checkVisitFail(error))
            })
    }
}

export const createVisit = (customerId, ticketId, date, selectedItems, checkedDateTime) => {
    return async (dispatch) => {
        let fiveMinutesAgo = new Date();
        fiveMinutesAgo.setMinutes(fiveMinutesAgo.getMinutes() - 5);

        if (fiveMinutesAgo > checkedDateTime) {
            dispatch(checkVisitTimeOut());
        } else {
            dispatch(createVisitRequest());

            const utcDate = new Date(date);
            utcDate.setMinutes(utcDate.getMinutes() - utcDate.getTimezoneOffset());
    
            await fetchPost(api.links.createVisit(), JSON.stringify({ customerId, ticketId, date: utcDate, selectedItems: selectedItems }))
                .then(() =>{
                    dispatch(createVisitSuccess());
                    dispatch(goToPage('Customer'));
                }).catch((error) => {
                    dispatch(createVisitFail(error))
                })
        }

    }
}