import React from 'react'
import { TableRow, TableCell } from '@material-ui/core'

const AnthropometricTableRow = ({ name, anthropometrics, value, sign = "" }) => (
    <TableRow>
        <TableCell>{name}</TableCell>
        {anthropometrics.map((a, index) =>
            <TableCell align="right" key={index}>
                {sign === ""
                    ? a[value]
                    : a[value] ? a[value] + sign : ""
                }
            </TableCell>
        )}
    </TableRow>
)

export default AnthropometricTableRow;