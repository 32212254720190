import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core'
import { setCustomerId } from '../../actions/MainActions';
import { bindActionCreators } from 'redux'
import { GetCustomerDefaultImageByAge } from '../../helpers/customer'
import birthdayImg from '../../content/images/Birthday.png'

const CustomerInfo = ({ customersList, setCustomerId, showAllCustomersIfMany }) => (
    customersList.map((customer, index) => {
        if (!showAllCustomersIfMany && index >= 5) {
            return null;
        }

        let colorWeight = 'black';

        if (customer.weight && customer.height) {
            const idealWeight = (customer.height - 110) * 1.15;

            if (idealWeight > 0 && customer.weight > 0)
            {
                colorWeight = customer.weight <= idealWeight
                    ? 'green'
                    : 'red';
            }
        }

        return <Grid
            container
            alignItems="center"
            key={index}
            style={{ cursor: "pointer" }}
            onClick={() => setCustomerId(customer.id)}
        >
            <Grid item>
                <Grid item className="roundImage" style={{ width: "36px", height: "36px", marginRight: "5px" }}>
                    <img src={customer.image ? 'data:image/png;base64, ' + customer.image : GetCustomerDefaultImageByAge(customer)} alt={customer.firstName} />
                </Grid>
                {customer.isBirthdayToday && <img src={birthdayImg} alt="Birthday" style={{ width: "24px" }} />}
                <span style={{backgroundColor: customer.isBirthdayToday ? "yellow" : "none"}}>
                    {customer.lastName} {customer.firstName},&nbsp;
                </span>
                {customer.age && <span>
                    {customer.age}лет,&nbsp;
                </span>}
                {customer.weight !== 0 && <span>
                    <span style={{ color: colorWeight }}>{customer.weight} кг</span>,&nbsp;
                </span>}
                <i>{customer.statuses.join(', ')}</i>
            </Grid>
        </Grid>
    })
)

const mapStateToProps = state => ({
    customersList: state.main.customersList,
    showAllCustomersIfMany: state.main.showAllCustomersIfMany,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    setCustomerId,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CustomerInfo);