import React, { Component } from 'react'
import Loading from '../Shared/Loading'
import avatarUserImg from '../../content/images/Trainer.png'
import { Grid } from '@material-ui/core'
import { getTrainerImage } from '../../actions/TrainerActions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

class TrainerImage extends Component {
    UNSAFE_componentWillMount() {
        this.props.getTrainerImage();
    }

    render() {
        const { trainer, errorMessage, isLoading, onClick, size, isNewImageLoading } = this.props;

        if (isLoading) {
            return <Loading />
        }

        if (errorMessage) {
            return <Grid item xs={12} style={{ textAlign: "center" }}>
                {errorMessage}
            </Grid>
        }

        return isNewImageLoading
            ? <Loading />
            : <div className="roundImage" style={{ width: size, height: size, float: "none" }}>
                <img
                    src={trainer && trainer.image
                        ? trainer.image.startsWith('data:image')
                            ? trainer.image
                            : 'data:image/png;base64, ' + trainer.image
                        : avatarUserImg}
                    onClick={onClick}
                    style={{ cursor: "pointer" }}
                    alt={trainer.name}
                />
            </div>
    }
}

const mapStateToProps = state => ({
    trainer: state.trainer.trainer,
    errorMessage: state.trainer.imageErrorMessage,
    isLoading: state.trainer.isImageLoading,
    isNewImageLoading: state.trainer.isNewImageLoading
})

const mapDispatchToProps = dispatch => bindActionCreators({
    getTrainerImage
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(TrainerImage)