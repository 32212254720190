import React, { Component } from 'react';
import { connect } from 'react-redux';
import NavMenu from './NavMenu';
import { Container } from '@material-ui/core'
import { bindActionCreators } from 'redux'
import { checkAutorization } from '../../actions/LoginActions';
import Loading from './Loading';
import { Grid } from '@material-ui/core'

class Layout extends Component {
    UNSAFE_componentWillMount() {
        this.props.checkAutorization();
    }

    render() {
        if (this.props.isGlobalLoading) {
            return <Loading />
        }

        if (this.props.errorMessageGlobal.length) {
            return <Grid xs={12}>{this.props.errorMessageGlobal}</Grid>
        }

        return <Container style={{ maxWidth: "375px", padding: "0px" }}>
            <div style={{ marginBottom: "85px" }}>
                {this.props.children}
            </div>
            <div style={{
                width: "373px",
                marginLeft: "auto",
                marginRight: "auto",
                position: "fixed",
                bottom: "0",
                padding: "12px 0 0 0",
                backgroundColor: "#d3d3d3"
            }}>
                <NavMenu />
            </div>
        </Container>
    }
}

const mapStateToProps = state => ({
    isGlobalLoading: state.login.isGlobalLoading,
    errorMessageGlobal: state.login.errorMessageGlobal,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    checkAutorization,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Layout);