import {
    CHANGE_USER_NAME,
    CHANGE_PASSWORD,
    ON_LOGIN_REQUEST,
    ON_LOGIN_SUCCESS,
    ON_LOGIN_FAIL,
    CHECK_AUTORIZATION_REQUEST,
    CHECK_AUTORIZATION_SUCCESS,
    CHECK_AUTORIZATION_FAIL,
    CLEAR_LOCAL_STORAGE
} from '../constants/Login'
import { fetchPost, fetchGet } from '../helpers/fetchHelper'
import api from '../constants/api'
import { goToPage } from './Route';

const onLoginRequest = () => ({ type: ON_LOGIN_REQUEST });
const onLoginSuccess = () => ({ type: ON_LOGIN_SUCCESS });
const onLoginFail = (error) => ({ type: ON_LOGIN_FAIL, error });

const checkAutorizationRequest = () => ({ type: CHECK_AUTORIZATION_REQUEST });
const checkAutorizationSuccess = () => ({ type: CHECK_AUTORIZATION_SUCCESS });
const checkAutorizationFail = (error) => ({ type: CHECK_AUTORIZATION_FAIL, error });

const clearLocalStorage = () => ({ type: CLEAR_LOCAL_STORAGE });

export const changeUserName = (value) => ({ type: CHANGE_USER_NAME, value });
export const changePassword = (value) => ({ type: CHANGE_PASSWORD, value });

export const checkAutorization = () => {
    return async (dispatch) => {
        dispatch(checkAutorizationRequest());

        await fetchGet(api.links.checkAutorization())
            .then((response) => {
                dispatch(checkAutorizationSuccess())
                if (response.status === api.responseStatuses.Unauthorized) {
                    dispatch(goToPage('/Login'));
                }
            })
            .catch((error) => {
                dispatch(checkAutorizationFail(error))
            })
    }
}

export const onLogin = (username, password) => {
    return async (dispatch) => {
        dispatch(onLoginRequest());

        await fetchPost(api.links.login(), JSON.stringify({ userName: username, password: password }))
            .then(() => {
                dispatch(onLoginSuccess());
                dispatch(goToPage('/'));
            })
            .catch((error) => {
                dispatch(onLoginFail(error))
            })
    }
}

export const onLogout = () => {
    return async (dispatch) => {
        await fetchPost(api.links.logout())
            .then(() => {
                dispatch(clearLocalStorage())
                dispatch(goToPage('/Login'));
            })
    }
}