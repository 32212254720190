import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomerInfo from './CustomerInfo';
import { getCustomersInsideClub } from '../../actions/MainActions';
import { bindActionCreators } from 'redux'
import { Grid } from '@material-ui/core'
import Loading from '../Shared/Loading'

class CustomersContainer extends Component {
    UNSAFE_componentWillMount() {
        this.props.getCustomersInsideClub();
    }

    render() {
        const { customersLoading, customersRequestErrorMessage, customersList } = this.props;

        if (customersLoading) {
            return <Loading />
        }

        if (customersRequestErrorMessage) {
            return <Grid item xs={12} style={{ textAlign: "center" }}>
                {customersRequestErrorMessage}
            </Grid>
        }
        
        if (customersList && customersList.length === 0){
            return <Grid item xs={12} style={{ textAlign: "center" }}>
                Клиентов в клубе нет!
            </Grid>
        }
        
        return <CustomerInfo />
    }
}

const mapStateToProps = state => ({
    customersLoading: state.main.customersLoading,
    customersRequestErrorMessage: state.main.customersRequestErrorMessage,
    customersList: state.main.customersList
})

const mapDispatchToProps = dispatch => bindActionCreators({
    getCustomersInsideClub,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CustomersContainer);