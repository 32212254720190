import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { Grid, FormControl, Select, MenuItem, InputLabel, Button } from "@material-ui/core"
import { getTicketTypes, changeSelectedTicketTypeId, changeSelectedDiscountNewTicket, onClickNewTicket } from '../../actions/CustomerActions'
import Loading from '../Shared/Loading';
import { getDiscounts } from '../../helpers/customer'
import { convertToHHMM } from '../../helpers/dateConverter'

class NewTicket extends Component {
    UNSAFE_componentWillMount() {
        this.props.getTicketTypes();
    }

    render() {
        const { customer, isLoading, errorMessage, ticketTypesList, selectedTicketTypeId,
            changeSelectedTicketTypeId, selectedDiscountNewTicket, changeSelectedDiscountNewTicket,
            newTicketRequestLoading, newTicketRequestImageError, onClickNewTicket } = this.props;

        if (isLoading)
            return <Loading />

        if (errorMessage)
            return <Grid item xs={12}>{errorMessage}</Grid>

        return <Grid container>
            <Grid item xs={12}>{customer.lastName} {customer.firstName}</Grid>
            <Grid item xs={12}>Выберите абонемент:</Grid>
            <Grid item xs={12}>
                <FormControl style={{ width: '100%' }}>
                    <InputLabel id="ticketTypeLabel">Абонемент</InputLabel>
                    <Select
                        labelId="ticketTypeLabel"
                        value={selectedTicketTypeId}
                        onChange={(e) => changeSelectedTicketTypeId(e.target.value)}
                    >
                        <MenuItem key="-1" value="" disabled>Не выбрано</MenuItem>
                        {ticketTypesList.map((ticketType, index) => (
                            <MenuItem key={index} value={ticketType.id}>{ticketType.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {selectedTicketTypeId !== '' && <Grid container xs={12}>
                    <Grid item xs={12}>Цена: {ticketTypesList.filter(i => i.id === selectedTicketTypeId)[0].price}</Grid>
                    <Grid item xs={12} style={{backgroundColor: "yellow"}}>
                        Цена со скидкой:&nbsp;
                        {Math.round(ticketTypesList.filter(i => i.id === selectedTicketTypeId)[0].price * (1 - selectedDiscountNewTicket / 100))}
                    </Grid>
                    <Grid item xs={12}>Тренировок: {ticketTypesList.filter(i => i.id === selectedTicketTypeId)[0].smartUnits}</Grid>
                    <Grid item xs={12}>Гостевых: {ticketTypesList.filter(i => i.id === selectedTicketTypeId)[0].smartGuestUnits}</Grid>
                    <Grid item xs={12}>Длительность: {ticketTypesList.filter(i => i.id === selectedTicketTypeId)[0].length} дней</Grid>
                    <Grid item xs={12}>Действует с: {convertToHHMM(ticketTypesList.filter(i => i.id === selectedTicketTypeId)[0].visitStart)}</Grid>
                    <Grid item xs={12}>Действует по: {convertToHHMM(ticketTypesList.filter(i => i.id === selectedTicketTypeId)[0].visitEnd)}</Grid>
                    <Grid item xs={12}>Минут солярия: {ticketTypesList.filter(i => i.id === selectedTicketTypeId)[0].solariumMinutes}</Grid>
                    <Grid item xs={12}>Количество бонусов: {ticketTypesList.filter(i => i.id === selectedTicketTypeId)[0].bonus}</Grid>
                    <Grid item xs={12}>Дней заморозки: {ticketTypesList.filter(i => i.id === selectedTicketTypeId)[0].maxFreezeDays}</Grid>
                </Grid>}

                <FormControl style={{ width: '100%' }}>
                    <InputLabel id="discountLabel">Скидка</InputLabel>
                    <Select
                        labelId="discountLabel"
                        value={selectedDiscountNewTicket}
                        onChange={(e) => changeSelectedDiscountNewTicket(e.target.value)}
                    >
                        {getDiscounts().map((value, index) => (
                            <MenuItem key={index} value={value}>{value}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            {newTicketRequestLoading
                ? <Loading />
                :
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ margin: "5px" }}
                        disabled={selectedTicketTypeId === ''}
                        onClick={() => onClickNewTicket(customer.id, selectedTicketTypeId, selectedDiscountNewTicket)}
                    >
                        Купить
                    </Button>
                </Grid>}

            {newTicketRequestImageError.length >= 0 && <Grid item xs={12}>{newTicketRequestImageError}</Grid>}
        </Grid>
    }
}

const mapStateToProps = state => ({
    customer: state.customer.customer,
    isLoading: state.customer.ticketTypeLoading,
    errorMessage: state.customer.ticketTypeRequestErrorMessage,
    ticketTypesList: state.customer.ticketTypesList,
    selectedTicketTypeId: state.customer.selectedTicketTypeId,
    selectedDiscountNewTicket: state.customer.selectedDiscountNewTicket,
    newTicketRequestLoading: state.customer.newTicketRequestLoading,
    newTicketRequestImageError: state.customer.newTicketRequestImageError
})

const mapDispatchToProps = dispatch => bindActionCreators({
    getTicketTypes,
    changeSelectedTicketTypeId,
    changeSelectedDiscountNewTicket,
    onClickNewTicket
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(NewTicket);