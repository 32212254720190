import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { Grid, Button, RadioGroup, FormLabel, Radio, FormControl, FormControlLabel } from '@material-ui/core'
import Loading from '../../Shared/Loading'
import { getTreatmentConfigs, selectDate, addNewItem, changeTreatmentConfigId, removeItem,
    checkVisit, changeSelectedTicketId, createVisit } from '../../../actions/VisitActions'
import { getCustomerTickets } from '../../../actions/CustomerActions'
import { goToPage } from '../../../actions/Route'
import { DateTimePicker } from '@material-ui/pickers'
import NewItem from './NewItem'
import Alert from '@material-ui/lab/Alert';
import CheckedNewItem from './CheckedNewItem'

class NewVisit extends Component {
    UNSAFE_componentWillMount() {
        this.props.getTreatmentConfigs();
        this.props.getCustomerTickets(this.props.customer.id);
    }

    render() {
        const { customer, isLoading, errorMessage, selectedDate, selectDate, selectedItems, addNewItem, allTreatmentsConfigs,
            changeTreatmentConfigId, removeItem, checkVisit, goToPage, createVisitRequestLoading, createVistitRequestErrorMessage,
            customerTicketsRequestLoading, customerTicketsErrorMessage, selectedTicketId, ticketsList, changeSelectedTicketId,
            checkVisitRequestLoading, checkVistitRequestErrorMessage, checkedVisit, checkedDateTime, createVisit } = this.props;

        const amountUnits = selectedItems.reduce((item1, item2) => item2['price'] == null ? item1 : item1 + item2['price'], 0);

        const canCheck = () =>
            selectedItems.length > 0
            && selectedItems.every(i => i.id)
            && amountUnits <= 8
            && !checkVisitRequestLoading
            && !checkedVisit;

        if (isLoading || customerTicketsRequestLoading)
            return <Loading />

        if (errorMessage)
            return <Grid item xs={12}>{errorMessage}</Grid>

        if (errorMessage)
            return <Grid item xs={12}>{customerTicketsErrorMessage}</Grid>

        if (!ticketsList || ticketsList.length === 0) {
            return <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={() => goToPage('/customer')}>Назад</Button><br />
                У клиента нет активного абонемента
            </Grid>
        }

        return <Grid container>
            <Grid item xs={12} style={{ textAlign: "center", marginBottom: "12px", fontWeight: "bold" }}>{customer.lastName} {customer.firstName}</Grid>
            <Grid item xs={12}>
                <DateTimePicker
                    style={{ width: "100%", padding: "5px" }}
                    label="Выберите дату и время"
                    format={"d MMMM yyyy     HH:mm"}
                    inputVariant="outlined"
                    minutesStep={5}
                    ampm={false}
                    showTodayButton
                    disablePast
                    value={selectedDate}
                    onChange={(e) => selectDate(e)} />
            </Grid>

            <Grid item xs={12}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Выберите абонемент</FormLabel>
                    <RadioGroup aria-label="Выберите абонемент" name="customer-ticket" value={selectedTicketId} onChange={(id) => changeSelectedTicketId(id.target.value)}>
                        {ticketsList.map((ticket, index) => (
                            <FormControlLabel key={index} value={ticket.id} control={<Radio />} label={"№" + ticket.number + " " + ticket.name + " осталось " + ticket.leftSmart + " Smart"} />
                        ))}
                    </RadioGroup>
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <i style={{ cursor: "pointer" }} onClick={() => addNewItem()}>Добавить услугу!</i>
            </Grid>

            {selectedItems.map((item, index) => (
                <NewItem
                    key={index}
                    item={item}
                    index={index}
                    allTreatmentsConfigs={allTreatmentsConfigs}
                    changeTreatmentConfigId={changeTreatmentConfigId}
                    removeItem={removeItem} />
            ))}

            {amountUnits > 8 && <Grid item xs={12}>
                <Alert severity="error">Выбрано больше 1 smart!</Alert>
            </Grid>}

            <Button
                disabled={!canCheck()}
                variant="contained"
                color="primary"
                onClick={() => checkVisit(customer.id, selectedTicketId, selectedDate, selectedItems)}
            >
                Проверить
            </Button>

            {checkVisitRequestLoading && <Loading />}

            {checkVistitRequestErrorMessage && <Grid item xs={12}>{checkVistitRequestErrorMessage}</Grid>}

            {checkedVisit && <Grid item xs={12}>
                {selectedItems.map((item, index) =>
                    <CheckedNewItem key={item.id} item={item} />
                )}
                <Button
                    disabled={createVisitRequestLoading}
                    variant="contained"
                    color="primary"
                    onClick={() => createVisit(customer.id, selectedTicketId, selectedDate, selectedItems, checkedDateTime)}
                >
                    Записать
                </Button>

                {createVisitRequestLoading && <Loading />}

                {createVistitRequestErrorMessage && <Grid item xs={12}>{createVistitRequestErrorMessage}</Grid>}
            </Grid>}
        </Grid>
    }
}

const mapStateToProps = state => ({
    customer: state.customer.customer,
    isLoading: state.customer.ticketTypeLoading,
    errorMessage: state.customer.ticketTypeRequestErrorMessage,
    selectedDate: state.visit.selectedDate,
    selectedItems: state.visit.selectedItems,
    allTreatmentsConfigs: state.visit.allTreatmentsConfigs,
    customerTicketsRequestLoading: state.customer.customerTicketsRequestLoading,
    customerTicketsErrorMessage: state.customer.customerTicketsErrorMessage,
    selectedTicketId: state.customer.selectedTicketId,
    ticketsList: state.customer.ticketsList,
    checkVistitRequestErrorMessage: state.visit.checkVistitRequestErrorMessage,
    checkVisitRequestLoading: state.visit.checkVisitRequestLoading,
    checkedVisit: state.visit.checkedVisit,
    checkedDateTime: state.visit.checkedDateTime,
    createVistitRequestErrorMessage: state.visit.createVistitRequestErrorMessage,
    createVisitRequestLoading: state.visit.createVisitRequestLoading,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    getTreatmentConfigs,
    selectDate,
    addNewItem,
    changeTreatmentConfigId,
    removeItem,
    checkVisit,
    getCustomerTickets,
    goToPage,
    changeSelectedTicketId,
    createVisit
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(NewVisit);