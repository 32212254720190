import React from 'react';
import { Grid, TextField, Button } from '@material-ui/core'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { changeUserName, changePassword, onLogin } from '../../actions/LoginActions'
import Loading from './Loading'

const Login = ({ changeUserName, changePassword, login, password, onLogin, errorMessage, isLoading }) => {
    
    const clickEnter = (e) => {
        if (e.which === 13 || e.keyCode === 13) {
            onLogin(login, password)
        }
    }

    return <Grid container>
        <Grid item x={12} style={{ margin: "24px auto 12px", fontSize: "24px", fontWeight: "bold" }}>
            Вход в систему
        </Grid>
        <Grid item xs={12}>
            <TextField
                fullWidth
                label="Логин"
                value={login}
                onKeyPress={clickEnter}
                onChange={(e) => changeUserName(e.target.value)}
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                fullWidth
                value={password}
                label="Пароль"
                type="password"
                onKeyPress={clickEnter}
                onChange={(e) => changePassword(e.target.value)}
            />
        </Grid>
        {isLoading
            ? <Loading />
            : <Grid item xs={12}>
                <Button
                    variant="outlined"
                    style={{ margin: "16px 5px" }}
                    disabled={!login.length || !password.length}
                    onClick={() => onLogin(login, password)}
                >
                    Войти
                </Button>
            </Grid>
        }
        {errorMessage && <Grid item xs={12}>
            {errorMessage}
        </Grid>}
    </Grid>
};

const mapStateToProps = state => ({
    login: state.login.login,
    password: state.login.password,
    isLoading: state.login.isLoading,
    errorMessage: state.login.errorMessage,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    changeUserName,
    changePassword,
    onLogin
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Login)