import React from 'react'
import logoImg from '../../content/images/logo.jpg'
import { goToPage } from '../../actions/Route'
import { Grid } from '@material-ui/core'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import TrainerImage from '../Trainer/TrainerImage'
import { onLogout } from '../../actions/LoginActions'

const HeadMain = ({ trainerName, goToPage, onLogout }) => {
    return <Grid container>
        <Grid item xs={8} style={{ textAlign: "center" }}>
            <img src={logoImg} style={{ height: "48px" }} alt='Тонус клуб' />
        </Grid>
        <Grid item xs={4} style={{ textAlign: "center" }}>
            <TrainerImage onClick={() => goToPage('/Trainer')} size="48px" />
        </Grid>
        <Grid item xs={8} />
        <Grid item xs={4} style={{ textAlign: "center", cursor: "pointer" }} onClick={() => goToPage('/Trainer')}>{trainerName}</Grid>
        <Grid item xs={8} />
        <Grid item xs={4} style={{ textAlign: "center", cursor: "pointer" }} onClick={() => onLogout()}>Выйти</Grid>
    </Grid>
}

const mapStateToProps = state => ({
    trainerName: state.trainer.trainer.name
})

const mapDispatchToProps = dispatch => bindActionCreators({
    goToPage,
    onLogout
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(HeadMain)